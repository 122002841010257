import {Box, Button} from "@mui/material";
import {useState} from "react";
import {ParticipantAssignmentFilter} from "src/pages/dashboard/super/participant-requests/participant-request-detail/participant-assignment-filter";
import {AddParticipantGroup} from "src/pages/dashboard/super/participant-requests/participant-request-detail/add-participant-group";
import {useTranslation} from "react-i18next";
import {ParticipantTakeout} from "src/pages/dashboard/super/participant-requests/participant-request-detail/participant-takeout";

export const AddParticipant = props => {
  const {handleNewAssignmentBatch, handleLoadingBatchTable} = props;
  const {t} = useTranslation();
  const [activeSection, setActiveSection] = useState("send");

  const handleSectionChange = section => () => setActiveSection(section);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: 4,
          marginBottom: 2,
        }}
      >
        <Button
          variant={activeSection === "send" ? "contained" : "outlined"}
          color="primary"
          onClick={handleSectionChange("send")}
          sx={{flex: 1, marginRight: 1}}
        >
          {t("findParticipantsRequest.sendParticipant")}
        </Button>
        <Button
          variant={activeSection === "addParticipantGroup" ? "contained" : "outlined"}
          color="primary"
          onClick={handleSectionChange("addParticipantGroup")}
          sx={{flex: 1, marginRight: 1}}
        >
          {t("findParticipantsRequest.addParticipantGroup")}
        </Button>
        <Button
          variant={activeSection === "takeOut" ? "contained" : "outlined"}
          color="primary"
          onClick={handleSectionChange("takeOut")}
          sx={{flex: 1, marginLeft: 1}}
        >
          {t("findParticipantsRequest.takeOutParticipant")}
        </Button>
      </Box>
      {activeSection === "send" ? (
        <ParticipantAssignmentFilter
          handleNewAssignmentBatch={handleNewAssignmentBatch}
        />
      ) : activeSection === "takeOut" ? (
        <ParticipantTakeout handleLoadingBatchTable={handleLoadingBatchTable} />
      ) : (
        activeSection === "addParticipantGroup" && (
          <AddParticipantGroup handleNewAssignmentBatch={handleNewAssignmentBatch} />
        )
      )}
    </>
  );
};
