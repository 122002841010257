import axiosInstance from "src/services/axiosInstance";

class ProjectService {
  async createRewardProject(payload) {
    return await axiosInstance
      .post(`/super/projects/project-reward/`, payload)
      .then(response => {
        return response?.data;
      })
      .catch(err => {
        throw err;
      });
  }

  async createOutsourceProject(payload) {
    return await axiosInstance
      .post(`/super/projects/outsource`, payload)
      .then(response => {
        return response?.data;
      })
      .catch(err => {
        throw err;
      });
  }

  async listOutsourceProjects(page) {
    return await axiosInstance
      .get(`/super/projects/outsource`, {
        params: {page: page},
      })
      .then(response => {
        return response?.data;
      })
      .catch(err => {
        throw err;
      });
  }

  async listRewardProject(page) {
    return await axiosInstance
      .get(`/super/projects/project-reward`, {
        params: {page: page},
      })
      .then(response => {
        return response?.data;
      })
      .catch(err => {
        console.log("err");
      });
  }
}

export const superProjectService = new ProjectService();
