import {Container} from "@mui/system";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import {useEffect, useState} from "react";
import MenuItem from "@mui/material/MenuItem";
import {LoadingButton} from "@mui/lab";
import {
  IconButton,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import GenericTablePagination from "src/components/generic/GenericTablePagination";
import {superProjectService} from "src/services/super/project/project";
import {OutsourceProject} from "src/entities/project/outsource-project";
import {useTranslation} from "react-i18next";
import {paths} from "src/pages/paths";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {useRouter} from "src/hooks/use-router";

export const CreateOutsourceSurvey = () => {
  const {t} = useTranslation();
  const router = useRouter();

  const [isLoading, setIsLoading] = useState(false);
  const [title, setTitle] = useState(null);
  const [description, setDescription] = useState(null);
  const [source, setSource] = useState(null);
  const [sourceUrl, setSourceUrl] = useState(null);

  const [isTableLoading, setIsTableLoading] = useState(false);
  const [projectCount, setProjectCount] = useState(0);
  const [page, setPage] = useState(0);
  const [projects, setProjects] = useState([]);

  const handleCreateRewardProject = () => {
    setIsLoading(true);
    const payload = {
      title: title,
      description: description,
      source: source,
      source_url: sourceUrl,
    };
    superProjectService.createOutsourceProject(payload).then(response => {
      setTitle("");
      setDescription("");
      setSource(null);
      setSourceUrl("");
      const newProject = new OutsourceProject(response);
      if (projects.length === 10) {
        setProjects([newProject, ...projects.slice(0, -1)]);
      } else {
        setProjects([newProject, ...projects]);
      }
      setIsLoading(false);
    });
  };

  const fetchProjects = _page => {
    setIsTableLoading(true);
    superProjectService.listOutsourceProjects(_page + 1).then(response => {
      setProjects(response.results.map(data => new OutsourceProject(data)));
      setProjectCount(response.count);
      setPage(_page);
      setIsTableLoading(false);
    });
  };

  useEffect(() => {
    fetchProjects(page);
  }, []);

  const renderTableBody = () => {
    if (isTableLoading) {
      return Array.from(new Array(5)).map((_, index) => (
        <TableRow key={index}>
          <TableCell>
            <Skeleton variant="text" width={200} />
          </TableCell>
          <TableCell>
            <Skeleton variant="text" width={200} />
          </TableCell>
          <TableCell>
            <Skeleton variant="text" width={200} />
          </TableCell>
          <TableCell>
            <Skeleton variant="text" width={100} />
          </TableCell>
          <TableCell>
            <Skeleton variant="text" width={200} />
          </TableCell>
          <TableCell>
            <Skeleton variant="circular" width={24} height={24} />
          </TableCell>
        </TableRow>
      ));
    }

    return projects.map(project => (
      <TableRow key={project.id} hover style={{cursor: "pointer"}}>
        <TableCell>{project.id}</TableCell>
        <TableCell>{project.topic}</TableCell>
        <TableCell>{project.source}</TableCell>
        <TableCell>{project.source_url}</TableCell>
        <TableCell align="right">
          <IconButton
            onClick={() =>
              router.push(
                paths.superDashboard.manageOutsourceSurveys.distributeOutsourceSurvey.replace(
                  ":id",
                  project.participant_request_id,
                ),
              )
            }
          >
            <ArrowForwardIosIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    ));
  };

  return (
    <Container maxWidth="lg">
      <Stack sx={{mt: 2}} spacing={12} direction={"row"}>
        <TextField
          fullWidth
          label="Title"
          value={title}
          onChange={e => setTitle(e.target.value)}
        />
      </Stack>
      <Stack sx={{mt: 2}} spacing={12} direction={"row"}>
        <TextField
          fullWidth
          label="Description"
          value={description}
          rows={4}
          multiline
          onChange={e => setDescription(e.target.value)}
        />
      </Stack>
      <Stack sx={{mt: 2}} spacing={2} direction={"row"}>
        <TextField
          fullWidth
          label="Source"
          name="source"
          select
          value={source}
          onChange={e => setSource(e.target.value)}
          sx={{px: 0}}
        >
          <MenuItem value="qualtrics">Qualtrics</MenuItem>
        </TextField>
      </Stack>
      <Stack sx={{mt: 2}} spacing={12} direction={"row"}>
        <TextField
          fullWidth
          label="URL"
          value={sourceUrl}
          onChange={e => setSourceUrl(e.target.value)}
        />
      </Stack>
      <Stack sx={{my: 2}} spacing={12} direction={"row"}>
        <LoadingButton
          fullWidth
          loading={isLoading}
          disabled={!(title && source && sourceUrl)}
          onClick={handleCreateRewardProject}
          sx={{mt: 3}}
          size="large"
          type="submit"
          variant="contained"
        >
          Create
        </LoadingButton>
      </Stack>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Title</TableCell>
              <TableCell>Source</TableCell>
              <TableCell>Source URL</TableCell>
              <TableCell align="right">{t("tableHeaders.actions")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{renderTableBody()}</TableBody>
        </Table>
      </TableContainer>
      <GenericTablePagination
        count={projectCount}
        onPageChange={newPage => fetchProjects(newPage)}
        page={page}
        rowsPerPage={10}
      />
    </Container>
  );
};
