import axiosInstance from "src/services/axiosInstance";

class PageService {
  async createPageBreak(payload) {
    return await axiosInstance
      .post(`/pages/`, payload)
      .then(block => {
        return block?.data;
      })
      .catch(err => {
        console.log("err");
      });
  }

  async removePageBreak(page_id) {
    return await axiosInstance
      .post(`/pages/${page_id}`)
      .then(block => {
        return block?.data;
      })
      .catch(err => {
        console.log("err");
      });
  }
}

export const pageService = new PageService();
