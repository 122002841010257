import {DashboardLayout} from "src/layouts/dashboard/dashboard-layout";
import {lazy, Suspense} from "react";
import {Outlet} from "react-router-dom";

const SurveyListPage = lazy(() => import("src/pages/admin/surveys"));
const NewSurveyPage = lazy(() => import("src/pages/dashboard/newsurvey"));
const ExcellToSurveyPage = lazy(() => import("src/pages/dashboard/excelltosurvey"));
const SettingCategoryPage = lazy(() => import("src/pages/dashboard/settingcategory"));
const FindParticipantsPage = lazy(() => import("src/pages/dashboard/findparticipants"));

export const dashboardRoutes = [
  {
    path: "dashboard",
    element: (
      <DashboardLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      {
        path: "surveys",
        element: <SurveyListPage />,
      },
      {
        path: "newsurvey",
        element: <NewSurveyPage />,
      },
      {
        path: "findparticipants",
        element: <FindParticipantsPage />,
      },
      {
        path: "settingcategory",
        element: <SettingCategoryPage />,
      },
      {
        path: "excelltosurvey",
        element: <ExcellToSurveyPage />,
      },
    ],
  },
];
