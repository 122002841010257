import {Grid, Container} from "@mui/material";
import {RewardStats} from "src/pages/dashboard/super/stats/reward-stats";
import {SurveyStats} from "src/pages/dashboard/super/stats/survey-stats";
import {ParticipantStats} from "src/pages/dashboard/super/stats/participant-stats";

export const Stats = () => {
  return (
    <Container maxWidth="xl">
      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Grid container spacing={3}>
            {/* Reward Stats */}
            <Grid item xs={12}>
              <RewardStats />
            </Grid>
            {/* Survey Stats */}
            <Grid item xs={12} mb={3}>
              <SurveyStats />
            </Grid>
            {/* Participant Stats */}
            <Grid item xs={12} mb={3}>
              <ParticipantStats />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};
