import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Card from "@mui/material/Card";
import GenericTablePagination from "src/components/generic/GenericTablePagination";
import {paths} from "src/pages/paths";
import {useRouter} from "src/hooks/use-router";
import {useParams} from "react-router-dom";

const TeamMembersTable = props => {
  const {id} = useParams();
  const {
    page,
    pageSize,
    count,
    paginatedTeamMembers,
    handlePageChange,
    onRowsPerPageChange,
  } = props;
  const router = useRouter();

  const handleClick = teamMemberId => {
    router.push(
      paths.superDashboard.teams.member
        .replace(":teamId", id)
        .replace(":teamMemberId", teamMemberId),
    );
  };

  return (
    <Card elevation={0}>
      <Table sx={{minWidth: 1150}}>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Phone</TableCell>
            <TableCell>Role</TableCell>
            <TableCell>Invited By</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {paginatedTeamMembers.map(teamMember => {
            return (
              <TableRow key={teamMember.id}>
                <TableCell
                  onClick={event => handleClick(teamMember.id)}
                  sx={{cursor: "pointer", color: "primary.main", fontWeight: "bold"}}
                >
                  {teamMember.id}
                </TableCell>
                <TableCell>{teamMember.email}</TableCell>
                <TableCell>{teamMember.phone}</TableCell>
                <TableCell>{teamMember.role}</TableCell>
                <TableCell
                  onClick={event => handleClick(teamMember.invited_by?.id)}
                  sx={{cursor: "pointer", color: "primary.main", fontWeight: "bold"}}
                >
                  {teamMember.invited_by?.phone}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      <GenericTablePagination
        count={count}
        onPageChange={handlePageChange}
        page={page}
        rowsPerPage={pageSize}
        onRowsPerPageChange={onRowsPerPageChange}
      />
    </Card>
  );
};

export default TeamMembersTable;
