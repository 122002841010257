import axiosInstance from "src/services/axiosInstance";

class ParticipantRequestService {
  async listParticipantRequests(page) {
    return await axiosInstance
      .get(`/super/participant-requests`, {
        params: {page: page},
      })
      .then(response => {
        return response?.data;
      })
      .catch(err => {
        console.log("err");
      });
  }

  async getParticipantRequestDetails(participantRequestId) {
    return await axiosInstance
      .get(`/super/participant-requests/${participantRequestId}`)
      .then(response => {
        return response?.data;
      })
      .catch(err => {
        console.log("err");
      });
  }

  async getParticipantRequestMeta(participantRequestId) {
    return await axiosInstance
      .get(`/super/participant-requests/${participantRequestId}/meta`)
      .then(response => {
        return response?.data;
      })
      .catch(err => {
        console.log("err");
      });
  }

  async setParticipantRequestMeta(participantRequestId, payload) {
    return await axiosInstance
      .post(`/super/participant-requests/${participantRequestId}/meta`, payload)
      .then(response => {
        return response?.data;
      })
      .catch(err => {
        console.log("err");
      });
  }

  async createProjectAssignment(payload) {
    return await axiosInstance
      .post(`/super/project-assignment/`, payload)
      .then(response => {
        return response?.data;
      })
      .catch(err => {
        throw err;
      });
  }

  async assignProjectAssignmentGroup(payload) {
    return await axiosInstance
      .post(`/super/project-assignment/project-assignment-group`, payload)
      .then(response => {
        return response?.data;
      })
      .catch(err => {
        throw err;
      });
  }

  async takeOutProjectAssignment(participantRequestId, payload) {
    return await axiosInstance
      .post(`/super/participant-requests/${participantRequestId}/take-out`, payload)
      .then(response => {
        return response?.data;
      })
      .catch(err => {
        throw err;
      });
  }

  async listProjectAssignmentBatches(participant_request_id, page) {
    return await axiosInstance
      .get(`/super/participant-requests/${participant_request_id}/project-assignment`, {
        params: {page: page},
      })
      .then(response => {
        return response?.data;
      })
      .catch(err => {
        console.log("err");
      });
  }

  async setProjectReward(participant_request_id, payload) {
    return await axiosInstance
      .post(`/super/participant-requests/${participant_request_id}/reward`, payload)
      .then(response => {
        return response?.data;
      })
      .catch(err => {
        console.log("err");
      });
  }

  async getProjectReward(participant_request_id) {
    return await axiosInstance
      .get(`/super/participant-requests/${participant_request_id}/reward`)
      .then(response => {
        return response?.data;
      })
      .catch(err => {
        console.log("err");
      });
  }

  async setProjectAssignmentBatchStatus(participant_request_id, payload) {
    return await axiosInstance
      .patch(
        `/super/participant-requests/${participant_request_id}/set-batch-status`,
        payload,
      )
      .then(response => {
        return response?.data;
      })
      .catch(err => {
        console.log("err");
      });
  }

  async sendProjectAssignmentNotification(participant_request_id, payload) {
    return await axiosInstance
      .post(`/super/participant-requests/${participant_request_id}/notification`, payload)
      .then(response => {
        return response?.data;
      })
      .catch(err => {
        throw err;
      });
  }
}

export const superParticipantRequestService = new ParticipantRequestService();
