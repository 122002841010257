import {Container} from "@mui/system";
import {CreateOutsourceSurvey} from "src/pages/dashboard/super/manage-outsource-surveys/create-outsource-survey";
import {Box, Tab, Tabs} from "@mui/material";
import {useState} from "react";

const CustomTabPanel = props => {
  const {children, value, index} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{width: "100%"}}
    >
      {value === index && <>{children}</>}
    </div>
  );
};

export const ManageOutsourceSurveys = () => {
  const [activeTab, setActiveTab] = useState("create");

  return (
    <Container maxWidth="lg">
      <Box sx={{borderBottom: 1, borderColor: "divider", mb: 2}}>
        <Tabs value={activeTab} onChange={(e, newVal) => setActiveTab(newVal)}>
          <Tab label={"Create Outsource Surveys"} value="create" />
        </Tabs>
      </Box>

      <CustomTabPanel value={activeTab} index="create">
        <CreateOutsourceSurvey />
      </CustomTabPanel>
    </Container>
  );
};
