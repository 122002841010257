import {Box, Button, Card, Grid, TextField, Typography} from "@mui/material";
import {superParticipantRequestService} from "src/services/super/project/participant-request";
import {useState} from "react";
import {useAuth} from "src/hooks/use-auth";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";

export const ParticipantTakeout = props => {
  const {handleLoadingBatchTable} = props;
  const {t} = useTranslation();
  const {id} = useParams();
  const [takeOutParticipantCount, setTakeOutParticipantCount] = useState(0);
  const auth = useAuth();

  const handleTakeOut = _ => {
    handleLoadingBatchTable(true);
    const payload = {
      participant_count: takeOutParticipantCount,
    };
    superParticipantRequestService
      .takeOutProjectAssignment(id, payload)
      .then(response => {
        auth.showAlert("success", response);
      })
      .catch(_ => {
        auth.showAlert("error", t("errorInTakeOut"));
      })
      .finally(_ => {
        handleLoadingBatchTable(false);
      });
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card sx={{width: "100%", alignItems: "center", px: 4, py: 2, mb: 4}}>
            <Typography variant="h6" component="div" sx={{py: 1}}>
              {t("sendParticipant.targetNumberOfParticipants")}
            </Typography>
            <TextField
              fullWidth
              label={t("sendParticipant.targetNumberOfParticipants")}
              value={takeOutParticipantCount}
              onChange={e => setTakeOutParticipantCount(e.target.value)}
              type="number"
            />
          </Card>
        </Grid>
      </Grid>
      <Box sx={{display: "flex", justifyContent: "flex-end", marginTop: 2}}>
        <Button variant="contained" color="primary" onClick={handleTakeOut}>
          {t("takeOutParticipant.takeOutParticipant")}
        </Button>
      </Box>
    </>
  );
};
