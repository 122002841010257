import axiosInstance from "src/services/axiosInstance";

class StatsService {
  async getApprovedRewardRequestStats() {
    return await axiosInstance
      .get(`/super/stats/approved-and-pending-rewards`)
      .then(response => {
        return response?.data;
      })
      .catch(err => {
        console.log("err");
      });
  }

  async getCompletedSurveyStats() {
    return await axiosInstance
      .get(`/super/stats/total-completed-projects`)
      .then(response => {
        return response?.data;
      })
      .catch(err => {
        console.log("err");
      });
  }

  async getParticipantStats() {
    return await axiosInstance
      .get(`/super/stats/participant-stats`)
      .then(response => {
        return response?.data;
      })
      .catch(err => {
        console.log("err");
      });
  }
}

export const superStatsService = new StatsService();
