import axiosInstance from "src/services/axiosInstance";

class TeamService {
  async listTeamMembers(teamId, page, page_size = 25, selected_filter) {
    return await axiosInstance
      .get(`/super/teams/${teamId}/members`, {
        params: {page: page, page_size: page_size, ...selected_filter},
      })
      .then(response => {
        return response?.data;
      })
      .catch(err => {
        console.log("err");
      });
  }

  async listTeamMemberProjects(teamId, teamMemberId, page) {
    return await axiosInstance
      .get(`/super/teams/${teamId}/members/${teamMemberId}/projects`, {
        params: {page: page},
      })
      .then(response => {
        return response?.data;
      })
      .catch(err => {
        console.log("err");
      });
  }

  async getTeamMemberInvitation(payload) {
    return await axiosInstance
      .post(`/super/teams/invitation`, payload)
      .then(response => {
        return response?.data;
      })
      .catch(err => {
        throw err;
      });
  }

  async generateTeamMemberPasswordResetURL(payload) {
    return await axiosInstance
      .post(`/super/teams/password-reset`, payload)
      .then(response => {
        return response?.data;
      })
      .catch(err => {
        throw err;
      });
  }

  async getTeamMemberDetails(teamMemberId) {
    return await axiosInstance
      .get(`/super/teams/team-member-informations/${teamMemberId}`)
      .then(response => {
        return response?.data;
      })
      .catch(err => {
        throw err;
      });
  }

  async getTeamMemberRewardsStats(teamMemberId) {
    return await axiosInstance
      .get(`super/teams/get-reward-stats/${teamMemberId}`)
      .then(response => {
        return response?.data;
      })
      .catch(err => {
        throw err;
      });
  }

  async exportTeamMembers(teamId, selected_filter) {
    const params = {
      ...selected_filter,
    };

    return await axiosInstance
      .get(`/super/teams/${teamId}/members/export-csv`, {
        params: params,
      })
      .then(response => {
        return response?.data;
      })
      .catch(err => {
        throw err;
      });
  }
}

export const superTeamService = new TeamService();
