export const paths = {
  about: "about",
  contact: "contact",
  home: "/",
  giftcodesinstructions: "giftcodesinstructions",
  survey: "/survey",
  auth: {
    login: "/auth/login",
  },
  dashboard: {
    index: "/dashboard",
    surveys: {
      list: "/dashboard/surveys",
    },
    excelltosurvey: "/dashboard/excelltosurvey",
  },
  superDashboard: {
    manageParticipantGroups: {
      list: "/super/dashboard/manage-participant-groups",
      details: "/super/dashboard/manage-participant-groups/:id",
    },
    manageParticipantRewards: {
      list: "/super/dashboard/manage-participant-rewards",
    },
    manageOutsourceSurveys: {
      list: "/super/dashboard/manage-outsource-surveys",
      distributeOutsourceSurvey: "/super/dashboard/manage-outsource-surveys/:id",
    },
    participantRequests: {
      list: "/super/dashboard/findpartrequests",
      details: "/super/dashboard/findpartrequests/:id",
    },
    participantPaymentRequest: {
      list: "/super/dashboard/participant-payment-request",
      details: "/super/dashboard/participant-payment-request/:id",
    },
    generateLinks: {
      index: "/super/dashboard/generate-links",
    },
    inviteParticipant: {
      index: "/super/dashboard/invite-participant",
    },
    manageBlacklist: {
      list: "/super/dashboard/manage-blacklist",
    },
    teams: {
      list: "/super/dashboard/teams",
      details: "/super/dashboard/teams/:slug",
      edit: "/super/dashboard/teams/:customerId/edit",
      member: "/super/dashboard/teams/:teamId/members/:teamMemberId",
    },
    stats: {
      index: "/super/dashboard/stats",
    },
  },
  surveyBuilder: {
    edit: "/survey-builder/:uuid/edit",
    surveyFlow: "/survey-builder/:uuid/survey-flow-builder",
    preview: "/survey-builder/:uuid/preview",
    distributions: "/survey-builder/:uuid/distributions",
    results: "/survey-builder/:uuid/results",
    analyticsDashboard: "/survey-builder/:uuid/analytics",
  },
};
