import {useState, useEffect} from "react";
import {t} from "i18next";
import {superStatsService} from "src/services/super/project/stats";
import {
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
} from "@mui/material";

export const SurveyStats = () => {
  const [surveyStats, setSurveyStats] = useState(null);

  useEffect(() => {
    superStatsService
      .getCompletedSurveyStats()
      .then(response => {
        if (response && typeof response === "object") {
          setSurveyStats(response);
        } else {
          console.error("Invalid response structure:", response);
        }
      })
      .catch(error => {
        console.error("Error fetching approved reward stats:", error);
      });
  }, []);

  if (!surveyStats) {
    return (
      <div style={{margin: 16}}>
        <Typography variant="h6" color="textSecondary" align="center">
          {t("stats.loading")}
        </Typography>
      </div>
    );
  }

  return (
    <div style={{margin: 16}}>
      <Paper
        elevation={2}
        sx={{
          padding: 2,
          borderRadius: 2,
          backgroundColor: "#f9f9f9",
          width: "150%",
          margin: "auto",
        }}
      >
        <Typography variant="h5" color="textPrimary" gutterBottom align="center">
          {t("stats.surveyStats")}
        </Typography>
        <Grid
          container
          spacing={3}
          alignItems="center"
          justifyContent="space-evenly"
          mt={2}
        >
          {/* Completed Projects Tablosu */}
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="h6" color="textSecondary" align="center" gutterBottom>
              {t("stats.totalSurveys")}
            </Typography>
            <TableContainer component={Paper} style={{marginBottom: 16}}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center"></TableCell>
                    <TableCell align="center"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="center">
                      {t("stats.totalSurveysLastWeek")}
                    </TableCell>
                    <TableCell align="center">
                      {surveyStats.last_week_completed_projects}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">
                      {t("stats.totalSurveysLastMonth")}
                    </TableCell>
                    <TableCell align="center">
                      {surveyStats.last_month_completed_projects}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">{t("stats.totalSurveys")}</TableCell>
                    <TableCell align="center">
                      {surveyStats.total_completed_projects}
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          {/* Participants Tablosu */}
          <Grid item xs={12} sm={6} md={4}>
            <Typography variant="h6" color="textSecondary" align="center" gutterBottom>
              {t("stats.totalParticipants")}
            </Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center"></TableCell>
                    <TableCell align="center"></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell align="center">
                      {t("stats.totalParticipantsLastWeek")}
                    </TableCell>
                    <TableCell align="center">
                      {surveyStats.last_week_participants}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">
                      {t("stats.totalParticipantsLastMonth")}
                    </TableCell>
                    <TableCell align="center">
                      {surveyStats.last_month_participants}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell align="center">{t("stats.totalParticipants")}</TableCell>
                    <TableCell align="center">{surveyStats.total_participants}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};
export default SurveyStats;
