import axiosInstance from "src/services/axiosInstance";

class TeamService {
  async createTeam(payload) {
    return await axiosInstance
      .post(`/teams`, payload)
      .then(survey => {
        return survey?.data;
      })
      .catch(err => {
        console.error("Error: ", err);
      });
  }

  async inviteTeamMember(teamId, payload) {
    return await axiosInstance
      .post(`/super/teams/${teamId}/members/bulk-invite`, payload)
      .then(invitation => {
        return invitation?.data;
      })
      .catch(err => {
        console.error("Error: ", err);
      });
  }

  async listTeams(page, page_size = 1) {
    return await axiosInstance
      .get(`/teams`, {
        params: {page: page, page_size: page_size},
      })
      .then(response => {
        console.log(response?.data);
        return response?.data;
      })
      .catch(err => {
        console.error("Error: ", err);
      });
  }
}

export const teamService = new TeamService();
