import {Container} from "@mui/system";
import {AddParticipant} from "src/pages/dashboard/super/participant-requests/participant-request-detail/add-participant";
import CircularProgress from "@mui/material/CircularProgress";
import {ProjectAssignmentBatchesTable} from "src/pages/dashboard/super/participant-requests/participant-request-detail/project-assignment-batches-table";
import {useEffect, useState} from "react";
import {Box, Button, Card, Grid, Typography} from "@mui/material";
import TextField from "@mui/material/TextField";
import {superParticipantRequestService} from "src/services/super/project/participant-request";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {ParticipantRequestMeta} from "src/pages/dashboard/super/participant-requests/participant-request-detail/participant-request-meta";

export const DistributeOutsourceSurvey = () => {
  const {id} = useParams();
  const {t} = useTranslation();
  const [newBatch, setNewBatch] = useState(null);
  const [loadingBatchTable, setLoadingBatchTable] = useState(false);

  const [projectRewardAmount, setProjectRewardAmount] = useState(0);
  const [saveRewardConditionLoading, setSaveRewardConditionLoading] = useState(false);

  useEffect(() => {
    superParticipantRequestService.getProjectReward(id).then(response => {
      setProjectRewardAmount(response.amount);
    });
  }, []);

  const handleSetReward = _ => {
    setSaveRewardConditionLoading(true);
    const payload = {
      amount: projectRewardAmount,
    };
    superParticipantRequestService.setProjectReward(id, payload).then(_ => {
      setSaveRewardConditionLoading(false);
    });
  };

  const handleNewAssignmentBatch = batch => {
    setNewBatch(batch);
  };

  const handleLoadingBatchTable = val => {
    setLoadingBatchTable(val);
  };

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" gutterBottom>
        Distribute Outsource Survey
      </Typography>

      <Grid container spacing={3}>
        <Grid item xl={12} lg={12} md={12} xs={12}>
          <Card
            sx={{
              width: "100%",
              alignItems: "center",
              px: 3,
              py: 2,
              mt: 2,
              minHeight: 125,
            }}
          >
            <Typography variant="h6" component="div" sx={{py: 1}}>
              {t("sendParticipant.rewardDefinition")}
            </Typography>
            <TextField
              fullWidth
              label={t("sendParticipant.rewardDefinition")}
              variant="outlined"
              value={projectRewardAmount}
              onChange={e => setProjectRewardAmount(e.target.value)}
              type={"number"}
            />
          </Card>
        </Grid>
      </Grid>
      <Box sx={{display: "flex", justifyContent: "flex-end", my: 2}}>
        <Button onClick={handleSetReward} variant="contained">
          {saveRewardConditionLoading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            "Set Reward"
          )}
        </Button>
      </Box>

      <ParticipantRequestMeta />
      <AddParticipant
        handleNewAssignmentBatch={handleNewAssignmentBatch}
        handleLoadingBatchTable={handleLoadingBatchTable}
      />
      {loadingBatchTable ? (
        <CircularProgress size={24} color="inherit" />
      ) : (
        <ProjectAssignmentBatchesTable
          newBatch={newBatch}
          handleNewAssignmentBatch={handleNewAssignmentBatch}
        />
      )}
    </Container>
  );
};
