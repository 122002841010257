import React, {useEffect, useState} from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Typography,
  Button,
  Box,
  TextField,
} from "@mui/material";
import {useTranslation} from "react-i18next";
import {superRewardService} from "src/services/super/project/reward";
import {useNavigate, useParams} from "react-router-dom";
import {RewardRequestDetail} from "src/entities/project/reward-request";
import {GifSplashScreen} from "src/components/gif-splash-screen";
import {rewardRequestStatus, RewardType} from "src/schemas/reward";
import CircularProgress from "@mui/material/CircularProgress";
import {useAuth} from "src/hooks/use-auth";
import {paths} from "src/pages/paths";
import RejectRequestButtonAndModal from "./reject-request-button-and-modal";
import {datetimeToObject} from "src/utils/datetime";

export const ParticipantPaymentRequestDetail = () => {
  const {id} = useParams();
  const {t} = useTranslation();
  const [paymentRequestDetail, setPaymentRequestDetail] = useState(null);
  const navigate = useNavigate();
  const [code, setCode] = useState("");
  const [pin, setPin] = useState("");
  const [isConfirmPaymentLoading, setIsConfirmPaymentLoading] = useState(false);
  const auth = useAuth();

  const getRewardRequestDetails = () => {
    superRewardService.getRewardRequestDetails(id).then(response => {
      const detail = new RewardRequestDetail(response);
      setPaymentRequestDetail(detail);
      setCode(detail.code);
      setPin(detail.pin);
    });
  };
  useEffect(() => {
    getRewardRequestDetails();
  }, []);

  if (paymentRequestDetail === null) {
    return <GifSplashScreen />;
  }

  const handleConfirmPayment = _ => {
    setIsConfirmPaymentLoading(true);
    let rewardCode = null;
    if (paymentRequestDetail.type === RewardType.A101) {
      rewardCode = `${code}|${pin}`;
    } else if (paymentRequestDetail.type === RewardType.MIGROS) {
      rewardCode = code;
    }
    const payload = {
      code: rewardCode,
    };
    superRewardService.approveRewardRequest(id, payload).then(response => {
      auth.showAlert("success", "Reward Request Approved!");
      const detail = new RewardRequestDetail(response);
      setPaymentRequestDetail(detail);
      setCode(detail.code);
      setPin(detail.pin);
      setIsConfirmPaymentLoading(false);
    });
  };

  const commonTextStyle = {
    fontWeight: "bold",
    color: "#d32f2f",
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        backgroundColor: "#f9f9f9",
      }}
    >
      <Box
        mt={2}
        mb={2}
        sx={{
          width: "80%",
          maxWidth: "1000px",
          backgroundColor: "white",
          padding: 4,
          borderRadius: 2,

          boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Typography
          variant="h4"
          sx={{marginBottom: 4, textAlign: "left", fontWeight: "bold"}}
        >
          {t("participantPaymentRequests.requestDetail")}
        </Typography>

        {/* Veri tablosu */}
        <TableContainer component={Paper} sx={{marginBottom: 3}}>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell>
                  <Typography variant="subtitle1" fontWeight="bold">
                    {t("participantPaymentRequests.selectedRequest")}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography sx={commonTextStyle}>{paymentRequestDetail.id}</Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="subtitle1" fontWeight="bold">
                    {t("participantPaymentRequests.email")}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography sx={commonTextStyle}>
                    {paymentRequestDetail.email}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="subtitle1" fontWeight="bold">
                    {t("participantPaymentRequests.phone")}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography sx={commonTextStyle}>
                    {paymentRequestDetail.phone}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="subtitle1" fontWeight="bold">
                    {t("participantPaymentRequests.teamMemberId")}
                  </Typography>
                </TableCell>
                <TableCell
                  sx={{
                    cursor: "pointer",
                    color: "primary.main",
                    fontWeight: "bold",
                  }}
                  onClick={event =>
                    navigate(
                      paths.superDashboard.teams.member
                        .replace(":teamId", paymentRequestDetail.team_member.team_id)
                        .replace(":teamMemberId", paymentRequestDetail.team_member.id),
                    )
                  }
                >
                  <Typography sx={commonTextStyle}>
                    {paymentRequestDetail.team_member.id}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="subtitle1" fontWeight="bold">
                    {t("participantPaymentRequests.participantCompletedSurvey")}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography sx={commonTextStyle}>
                    {paymentRequestDetail.completed_survey_count}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="subtitle1" fontWeight="bold">
                    {t("participantPaymentRequests.createdAt")}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography sx={commonTextStyle}>
                    {datetimeToObject(paymentRequestDetail.created_at)}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="subtitle1" fontWeight="bold">
                    {t("participantPaymentRequests.updatedAt")}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography sx={commonTextStyle}>
                    {datetimeToObject(paymentRequestDetail.updated_at)}
                  </Typography>
                </TableCell>
              </TableRow>
              {paymentRequestDetail.message && (
                <TableRow>
                  <TableCell>
                    <Typography variant="subtitle1" fontWeight="bold">
                      {t("participantPaymentRequests.message")}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography sx={commonTextStyle}>
                      {paymentRequestDetail.message}
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
              <TableRow>
                <TableCell>
                  <Typography variant="subtitle1" fontWeight="bold">
                    {t("participantPaymentRequests.participantCompletedSurveyDuration")}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography sx={commonTextStyle}>
                    {paymentRequestDetail.average_submission_duration}{" "}
                    {t("participantPaymentRequests.minute")}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="subtitle1" fontWeight="bold">
                    {t("participantPaymentRequests.requestedAmount")}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography sx={commonTextStyle}>
                    {paymentRequestDetail.amount}
                  </Typography>
                </TableCell>
              </TableRow>
              {paymentRequestDetail.type === RewardType.CASH && (
                <TableRow>
                  <TableCell>
                    <Typography variant="subtitle1" fontWeight="bold">
                      {t("participantPaymentRequests.giftAmount")}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography sx={commonTextStyle}>
                      {paymentRequestDetail.gift_amount}
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
              <TableRow>
                <TableCell>
                  <Typography variant="subtitle1" fontWeight="bold">
                    {t("participantPaymentRequests.requestedMarket")}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography sx={commonTextStyle}>
                    {paymentRequestDetail.type}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>
                  <Typography variant="subtitle1" fontWeight="bold">
                    {t("participantPaymentRequests.requestedStatus")}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography sx={commonTextStyle}>
                    {paymentRequestDetail.status}
                  </Typography>
                </TableCell>
              </TableRow>
              {/* A101 CODE AND PIN */}
              {paymentRequestDetail.type === RewardType.A101 && (
                <>
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle1" fontWeight="bold">
                        {t("code")}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <TextField
                        fullWidth
                        label={t("code")}
                        variant="outlined"
                        value={code}
                        onChange={e => setCode(e.target.value)}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle1" fontWeight="bold">
                        {t("pin")}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <TextField
                        fullWidth
                        label={t("pin")}
                        variant="outlined"
                        value={pin}
                        onChange={e => setPin(e.target.value)}
                      />
                    </TableCell>
                  </TableRow>
                </>
              )}

              {/* MIGROS CODE */}
              {paymentRequestDetail.type === RewardType.MIGROS && (
                <TableRow>
                  <TableCell>
                    <Typography variant="subtitle1" fontWeight="bold">
                      {t("code")}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <TextField
                      fullWidth
                      label={t("code")}
                      variant="outlined"
                      value={code}
                      onChange={e => setCode(e.target.value)}
                    />
                  </TableCell>
                </TableRow>
              )}

              {/* IBAN */}
              {paymentRequestDetail.type === RewardType.CASH && (
                <>
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle1" fontWeight="bold">
                        {t("participantPaymentRequests.bank_full_name")}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={commonTextStyle}>
                        {paymentRequestDetail.bank_details.bank_full_name}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle1" fontWeight="bold">
                        {t("participantPaymentRequests.bank_name")}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={commonTextStyle}>
                        {paymentRequestDetail.bank_details.bank_name}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle1" fontWeight="bold">
                        {t("participantPaymentRequests.bank_iban")}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={commonTextStyle}>
                        {paymentRequestDetail.bank_details.bank_iban}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle1" fontWeight="bold">
                        {t("participantPaymentRequests.bank_identity_number")}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={commonTextStyle}>
                        {paymentRequestDetail.bank_details.bank_identity_number}
                      </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>
                      <Typography variant="subtitle1" fontWeight="bold">
                        {t("participantPaymentRequests.bank_address")}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={commonTextStyle}>
                        {paymentRequestDetail.bank_details.bank_province
                          ? `${paymentRequestDetail.bank_details.bank_province}, ${paymentRequestDetail.bank_details.bank_neighborhood}, ${paymentRequestDetail.bank_details.bank_street}, ${paymentRequestDetail.bank_details.bank_building}, ${paymentRequestDetail.bank_details.bank_postal_code}`
                          : paymentRequestDetail.bank_details.bank_address}
                      </Typography>
                    </TableCell>
                  </TableRow>
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {paymentRequestDetail.status === rewardRequestStatus.PENDING && (
          <Box sx={{textAlign: "right"}}>
            <RejectRequestButtonAndModal
              id={id}
              getRewardRequestDetails={getRewardRequestDetails}
            />
            <Button
              disabled={isConfirmPaymentLoading}
              onClick={handleConfirmPayment}
              sx={{
                borderRadius: "20px",
                marginLeft: 1,
                marginRight: 1,
                paddingLeft: 2,
                paddingRight: 2,
                fontWeight: "bold",
              }}
              type="submit"
              variant="contained"
            >
              {isConfirmPaymentLoading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                t("participantPaymentRequests.confirmPayment")
              )}
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  );
};
