import {
  Drawer,
  Box,
  Typography,
  TextField,
  Button,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Switch,
  FormControlLabel,
} from "@mui/material";
import {useState, useEffect} from "react";
import {RewardType} from "src/schemas/reward";

const RewardTypeIncludeAll = {
  ALL: "",
  ...RewardType,
};

export const defaultRewardsFilters = {
  start_date: "",
  end_date: "",
  phone: "",
  email: "",
  reward_type: RewardTypeIncludeAll.ALL,
  includeFlagged: true,
};

const FilterDrawer = ({open, onClose, onApply, selectedFilter, activeTab}) => {
  const [filters, setFilters] = useState(defaultRewardsFilters);

  useEffect(() => {
    setFilters(selectedFilter || defaultRewardsFilters);
  }, [selectedFilter]);

  const handleChange = (field, value) => {
    setFilters(prev => ({...prev, [field]: value}));
  };

  const handleApply = () => {
    onApply(filters);
    onClose();
  };

  const handleClear = () => {
    setFilters(defaultRewardsFilters);
    onApply(defaultRewardsFilters);
    onClose();
  };

  const isFilterChanged =
    JSON.stringify(filters) !== JSON.stringify(defaultRewardsFilters);

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      sx={{
        "& .MuiDrawer-paper": {
          maxWidth: 500,
          width: {xs: "90%", sm: "60%", md: "50%"},
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        },
      }}
    >
      <Typography variant="h6" mb={2}>
        Filter Options
      </Typography>

      <Box display="flex" gap={2} mb={2}>
        <TextField
          label="Start Date"
          type="date"
          value={filters.start_date}
          onChange={e => handleChange("start_date", e.target.value)}
          InputLabelProps={{shrink: true}}
          fullWidth
        />
        <TextField
          label="End Date"
          type="date"
          value={filters.end_date}
          onChange={e => handleChange("end_date", e.target.value)}
          InputLabelProps={{shrink: true}}
          fullWidth
        />
      </Box>

      <TextField
        label="Phone"
        value={filters.phone}
        onChange={e => handleChange("phone", e.target.value)}
        fullWidth
        sx={{mb: 2}}
      />
      <TextField
        label="Email"
        value={filters.email}
        onChange={e => handleChange("email", e.target.value)}
        fullWidth
        sx={{mb: 2}}
      />

      <FormControl fullWidth sx={{mb: 2}}>
        <InputLabel shrink>Reward Type</InputLabel>
        <Select
          value={filters.reward_type}
          onChange={e => handleChange("reward_type", e.target.value)}
          displayEmpty
        >
          {Object.values(RewardTypeIncludeAll).map(type => (
            <MenuItem key={type} value={type}>
              {type === RewardTypeIncludeAll.ALL ? "Tümü" : type}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {activeTab !== "flagged" && (
        <Box sx={{borderColor: "divider", mb: 2}}>
          <FormControlLabel
            control={
              <Switch
                checked={filters.includeFlagged}
                onChange={e => handleChange("includeFlagged", e.target.checked)}
                color="primary"
              />
            }
            label="Include Flagged"
          />
        </Box>
      )}

      <Box display="flex" justifyContent="space-between" mt={3}>
        <Button disabled={!isFilterChanged} onClick={handleClear}>
          Clear Filter
        </Button>

        <Box display="flex" gap={2}>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="contained" onClick={handleApply}>
            Apply
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};

export default FilterDrawer;
