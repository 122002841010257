import axiosInstance from "src/services/axiosInstance";

class RewardService {
  async listRewardRequests(
    page,
    status,
    is_flagged,
    page_size = 25,
    team_member_id,
    otherFilters,
  ) {
    const params = {
      page: page,
      page_size: page_size,
      status: status,
      is_flagged: is_flagged,
      team_member_id: team_member_id,
      ...otherFilters,
    };

    return await axiosInstance
      .get(`/super/reward-requests`, {
        params,
      })
      .then(response => {
        return response?.data;
      })
      .catch(err => {
        console.log("err");
      });
  }

  async exportRewardRequests(status, is_flagged, otherFilters) {
    const params = {
      status: status,
      is_flagged: is_flagged,
      ...otherFilters,
    };
    return await axiosInstance
      .get(`/super/reward-requests/export-csv`, {
        params: params,
      })
      .then(response => {
        return response?.data;
      })
      .catch(err => {
        console.log("err");
      });
  }

  async getRewardRequestDetails(id) {
    return await axiosInstance
      .get(`/super/reward-requests/${id}`)
      .then(response => {
        return response?.data;
      })
      .catch(err => {
        console.log("err");
      });
  }

  async approveRewardRequest(id, payload) {
    return await axiosInstance
      .patch(`/super/reward-requests/${id}`, payload)
      .then(response => {
        return response?.data;
      })
      .catch(err => {
        console.log("err");
      });
  }

  async rejectRewardRequest(id, payload) {
    return await axiosInstance
      .patch(`/super/reward-requests/${id}/reject`, payload)
      .then(response => {
        return response?.data;
      })
      .catch(err => {
        throw err;
      });
  }

  async addReward(payload) {
    return await axiosInstance
      .post(`/super/reward`, payload)
      .then(response => {
        return response?.data;
      })
      .catch(err => {
        console.log("err");
      });
  }

  async flagReward(id) {
    return await axiosInstance
      .put(`/super/reward/${id}/flag`)
      .then(response => {
        return response?.data;
      })
      .catch(err => {
        throw err;
      });
  }

  async unflagReward(id) {
    return await axiosInstance
      .put(`/super/reward/${id}/unflag`)
      .then(response => {
        return response?.data;
      })
      .catch(err => {
        throw err;
      });
  }
}

export const superRewardService = new RewardService();
