import React, {useEffect, useState} from "react";
import {superStatsService} from "src/services/super/project/stats";
import {PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer} from "recharts";
import {Paper, Typography, Grid, Select, MenuItem} from "@mui/material";
import {BarChart, Bar, XAxis, YAxis, CartesianGrid, Line, LineChart} from "recharts";
import {t} from "i18next";

const COLORS = {
  pending: "#dbab47",
  approved: "#233366",
};

export const RewardStats = () => {
  const [approvedStats, setApprovedStats] = useState([]);
  const [chartType, setChartType] = useState("BarChart");

  useEffect(() => {
    superStatsService
      .getApprovedRewardRequestStats()
      .then(response => {
        if (response && Array.isArray(response)) {
          setApprovedStats(response);
        } else {
          console.error("Invalid response structure:", response);
        }
      })
      .catch(error => {
        console.error("Error fetching approved reward stats:", error);
      });
  }, []);

  const groupedByType = approvedStats.reduce((acc, stat) => {
    if (!acc[stat.type]) {
      acc[stat.type] = [];
    }
    acc[stat.type].push(stat);
    return acc;
  }, {});

  const cashData =
    groupedByType["cash"]?.reduce((acc, stat) => {
      const existing = acc.find(item => item.amount === stat.amount);
      if (existing) {
        existing.count += stat.count;
      } else {
        acc.push({
          amount: stat.amount,
          count: stat.count,
        });
      }
      return acc;
    }, []) || [];

  return (
    <div style={{margin: 16}}>
      <Paper
        elevation={2}
        sx={{
          padding: 2,
          borderRadius: 2,
          backgroundColor: "#f9f9f9",
          width: "150%",
        }}
      >
        <Typography variant="h5" color="textPrimary" gutterBottom align="center">
          {t("stats.rewardStats")}
        </Typography>
        <div style={{marginBottom: 16}}>
          <Select value={chartType} onChange={e => setChartType(e.target.value)}>
            <MenuItem value="BarChart">{t("stats.barChart")}</MenuItem>
            <MenuItem value="PieChart">{t("stats.pieChart")}</MenuItem>
          </Select>
        </div>

        <Grid container spacing={3} justifyContent="center">
          {chartType === "BarChart"
            ? Object.keys(groupedByType).map((type, index) => {
                const data = groupedByType[type].reduce((acc, stat) => {
                  const existing = acc.find(item => item.amount === stat.amount);
                  if (existing) {
                    existing[stat.status] = stat.count;
                  } else {
                    acc.push({
                      amount: stat.amount,
                      [stat.status]: stat.count,
                    });
                  }
                  return acc;
                }, []);

                return (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <Paper style={{padding: 16}}>
                      <Typography
                        variant="h6"
                        color="textSecondary"
                        align="center"
                        gutterBottom
                      >
                        {t(`stats.${type}`)}
                      </Typography>
                      <ResponsiveContainer width="100%" height={400}>
                        <BarChart data={data}>
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis
                            dataKey="amount"
                            label={{
                              value: t("stats.requestAmount"),
                              position: "insideBottom",
                              offset: -20,
                              style: {textAnchor: "middle", padding: 10},
                            }}
                            tick={{
                              angle: -45,
                              textAnchor: "end",
                            }}
                          />
                          <YAxis
                            label={{
                              value: t("stats.requestCount"),
                              angle: -90,
                              position: "insideLeft",
                              style: {textAnchor: "middle"},
                            }}
                            tickFormatter={value =>
                              Number.isInteger(value) ? value : ""
                            }
                            domain={[0, "dataMax"]}
                            allowDecimals={false}
                          />
                          <Tooltip />
                          <Legend wrapperStyle={{paddingTop: 25}} />
                          <Bar
                            dataKey="approved"
                            fill="#233366"
                            name={t("stats.approvedAmount")}
                            barSize={25}
                          />
                          <Bar
                            dataKey="pending"
                            fill="#dbab47"
                            name={t("stats.pendingAmount")}
                            barSize={25}
                          />
                        </BarChart>
                      </ResponsiveContainer>
                    </Paper>
                  </Grid>
                );
              })
            : Object.keys(groupedByType).map((type, index) => {
                const pieData = groupedByType[type].reduce((acc, stat) => {
                  if (!acc[stat.status]) {
                    acc[stat.status] = 0;
                  }
                  acc[stat.status] += stat.amount * stat.count;
                  return acc;
                }, {});

                const pieChartData = Object.keys(pieData).map(status => ({
                  name:
                    status === "pending"
                      ? t("stats.pendingAmount")
                      : t("stats.approvedAmount"),
                  value: pieData[status],
                }));

                return (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <Paper style={{padding: 16}}>
                      <Typography
                        variant="h6"
                        color="textSecondary"
                        align="center"
                        gutterBottom
                      >
                        {t(`stats.${type}`)}
                      </Typography>
                      <ResponsiveContainer width="100%" height={350}>
                        <PieChart>
                          <Pie
                            data={pieChartData}
                            dataKey="value"
                            nameKey="name"
                            cx="50%"
                            cy="50%"
                            outerRadius={100}
                            fill="#8884d8"
                            label
                          >
                            {pieChartData.map((entry, index) => (
                              <Cell
                                key={`cell-${index}`}
                                fill={
                                  COLORS[
                                    entry.name === t("stats.pendingAmount")
                                      ? "pending"
                                      : "approved"
                                  ]
                                }
                              />
                            ))}
                          </Pie>
                          <Tooltip />
                          <Legend />
                        </PieChart>
                      </ResponsiveContainer>
                    </Paper>
                  </Grid>
                );
              })}

          {cashData.length > 0 && (
            <Grid item xs={12} sm={12} md={12}>
              <Paper style={{padding: 16}}>
                <Typography
                  variant="h6"
                  color="textSecondary"
                  align="center"
                  gutterBottom
                >
                  {t("stats.cashRewards")}
                </Typography>
                <ResponsiveContainer width="100%" height={400}>
                  <LineChart data={cashData} margin={{bottom: 50}}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                      dataKey="amount"
                      label={{
                        value: t("stats.requestAmount"),
                        position: "insideBottom",
                        offset: -25,
                        style: {textAnchor: "middle", padding: 10},
                      }}
                      tick={{
                        angle: -45,
                        textAnchor: "end",
                      }}
                    />
                    <YAxis
                      label={{
                        value: t("stats.requestCount"),
                        angle: -90,
                        position: "insideLeft",
                        style: {textAnchor: "middle"},
                      }}
                      tickFormatter={value => (Number.isInteger(value) ? value : "")}
                      domain={[0, "dataMax"]}
                      allowDecimals={false}
                    />
                    <Tooltip />
                    <Legend verticalAlign="top" height={36} />
                    <Line
                      type="monotone"
                      dataKey="count"
                      stroke="#8884d8"
                      strokeWidth={2}
                      dot={{r: 5}}
                      activeDot={{r: 8}}
                      name={t("stats.requestCount")}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </Paper>
            </Grid>
          )}
        </Grid>
      </Paper>
    </div>
  );
};

export default RewardStats;
