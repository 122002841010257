import TextField from "@mui/material/TextField";
import {useState} from "react";
import {Container} from "@mui/system";
import {LoadingButton} from "@mui/lab";
import {superTeamService} from "src/services/super/team";
import {Grid, Typography} from "@mui/material";

export const TeamMemberInvitation = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [joinUrl, setJoinUrl] = useState("");
  const [loading, setLoading] = useState(false);

  const getTeamMemberInvitation = () => {
    setLoading(true);
    const payload = {
      phone: phoneNumber,
    };
    superTeamService
      .getTeamMemberInvitation(payload)
      .then(response => {
        setJoinUrl(response.url);
      })
      .catch(_ => setJoinUrl("NOT FOUND"))
      .finally(_ => setLoading(false));
  };

  return (
    <Container maxWidth="lg">
      <Grid container sx={12} p={2}>
        <Typography variant="h6" component="div" sx={{py: 1}}>
          Generate Team Member Invitation URL
        </Typography>
        <TextField
          fullWidth
          label="Phone"
          value={phoneNumber}
          onChange={e => setPhoneNumber(e.target.value)}
        />
        <LoadingButton
          fullWidth
          loading={loading}
          onClick={getTeamMemberInvitation}
          sx={{mt: 3}}
          size="large"
          type="submit"
          variant="contained"
        >
          Generate
        </LoadingButton>
        <Typography variant="h6" component="div" sx={{py: 1}}>
          {joinUrl}
        </Typography>
      </Grid>
    </Container>
  );
};
