import {useEffect, useState} from "react";
import {
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  CircularProgress,
  Stack,
} from "@mui/material";
import {superRewardService} from "src/services/super/project/reward";
import {superTeamService} from "src/services/super/team";
import Box from "@mui/material/Box";
import {useTranslation} from "react-i18next";
import {RewardRequest, TeamMemberRewardsStats} from "src/entities/project/reward-request";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import {datetimeToObject} from "src/utils/datetime";
import GenericTablePagination from "src/components/generic/GenericTablePagination";
import {useParams} from "react-router-dom";

export const Rewards = () => {
  const {t} = useTranslation();
  const {teamMemberId} = useParams();
  const [loading, setLoading] = useState(false);
  const [rewardStats, setRewardStats] = useState(new TeamMemberRewardsStats({}));

  const [page, setPage] = useState(1);
  const [paymentRequests, setPaymentRequests] = useState([]);
  const [totalPaymentRequestCount, setTotalPaymentRequestCount] = useState(0);

  const fetchRewardRequests = _page => {
    setLoading(true);
    superRewardService
      .listRewardRequests(_page, null, null, 10, teamMemberId)
      .then(response => {
        const results = response.results.map(res => new RewardRequest(res));
        setPaymentRequests(results);
        setPage(_page);
        setTotalPaymentRequestCount(response.count);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchRewardStats = async teamMemberId => {
    setLoading(true);
    try {
      const response = await superTeamService.getTeamMemberRewardsStats(teamMemberId);
      const stats = new TeamMemberRewardsStats(response);
      setRewardStats(stats);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRewardRequests(page);
    fetchRewardStats(teamMemberId);
  }, []);

  const renderTableBody = () => {
    if (loading) {
      return Array.from(new Array(5)).map((_, index) => (
        <TableRow key={index}>
          <TableCell colSpan={4} align="center">
            <CircularProgress size={24} />
          </TableCell>
        </TableRow>
      ));
    }

    return paymentRequests.map(paymentRequest => (
      <TableRow key={paymentRequest.id} hover style={{cursor: "pointer"}}>
        <TableCell>
          <Box sx={{display: "flex", alignItems: "center"}}>
            <FiberManualRecordIcon
              fontSize="small"
              // Duruma göre renk ayarı
              color={
                paymentRequest.status === "approved"
                  ? "success"
                  : paymentRequest.status === "pending"
                    ? "warning"
                    : "error" // rejected için kırmızı
              }
              sx={{marginRight: 1}}
            />
            {paymentRequest.status}
          </Box>
        </TableCell>
        <TableCell>{paymentRequest.amount + (paymentRequest.gift_amount ?? 0)}</TableCell>
        <TableCell>{paymentRequest.type}</TableCell>
        <TableCell>{datetimeToObject(paymentRequest.created_at)}</TableCell>
      </TableRow>
    ));
  };
  return (
    <Typography
      variant="h5"
      sx={{marginBottom: 4, marginTop: 0, textAlign: "left", fontWeight: "bold"}}
    >
      <Box
        sx={{
          mb: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h5">
          {t("participantPaymentRequests.participantPaymentRequests")}
        </Typography>
        <Stack direction="row" spacing={2}>
          <Paper
            elevation={2}
            sx={{
              padding: 2,
              borderRadius: 2,
              textAlign: "center",
              backgroundColor: "#f9f9f9",
              minWidth: 120,
              maxWidth: 165,
            }}
          >
            <Typography variant="subtitle2" color="textSecondary" sx={{mb: 1}}>
              {t("rewards.availablePoints")}
            </Typography>
            <Typography variant="h6">{rewardStats.available_reward_amount}</Typography>
          </Paper>

          <Paper
            elevation={2}
            sx={{
              padding: 2,
              borderRadius: 2,
              textAlign: "center",
              backgroundColor: "#f9f9f9",
              minWidth: 120,
              maxWidth: 159,
            }}
          >
            <Typography variant="subtitle2" color="textSecondary" sx={{mb: 1}}>
              {t("rewards.pendingPoints")}
            </Typography>
            <Typography variant="h6">{rewardStats.pending_reward_amount}</Typography>
          </Paper>

          <Paper
            elevation={2}
            sx={{
              padding: 2,
              borderRadius: 2,
              textAlign: "center",
              backgroundColor: "#f9f9f9",
              minWidth: 120,
              maxWidth: 159,
            }}
          >
            <Typography variant="subtitle2" color="textSecondary" sx={{mb: 1}}>
              {t("rewards.usedPoints")}
            </Typography>
            <Typography variant="h6">{rewardStats.used_reward_amount}</Typography>
          </Paper>
        </Stack>
      </Box>

      <Box sx={{py: 4}}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t("participantPaymentRequests.requestedStatus")}</TableCell>
                <TableCell>{t("participantPaymentRequests.requestedAmount")}</TableCell>
                <TableCell>{t("participantPaymentRequests.requestedMarket")}</TableCell>
                <TableCell>{t("participantPaymentRequests.createdTime")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{renderTableBody()}</TableBody>
          </Table>
        </TableContainer>
        <GenericTablePagination
          page={page - 1}
          rowsPerPage={10}
          count={totalPaymentRequestCount}
          onPageChange={newPage => fetchRewardRequests(newPage + 1)}
        />
      </Box>
    </Typography>
  );
};
