import {useTranslation} from "react-i18next";
import SvgIcon from "@mui/material/SvgIcon";
import {paths} from "src/pages/paths";
import BuildingIcon from "src/icons/building";
import BallotIcon from "@mui/icons-material/Ballot";
import AddToPhotosIcon from "@mui/icons-material/AddToPhotos";
import FindParticiIcon from "@mui/icons-material/GroupAdd";
import SettingsIcon from "@mui/icons-material/Settings";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import GppBadIcon from "@mui/icons-material/GppBad";
import AddLinkIcon from "@mui/icons-material/AddLink";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import GroupsIcon from "@mui/icons-material/Groups";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import PersonSearchIcon from "@mui/icons-material/PersonSearch";
import WorkspacesIcon from "@mui/icons-material/Workspaces";
import BarChartOutlinedIcon from "@mui/icons-material/BarChartOutlined";
import {useAuth} from "src/hooks/use-auth";

export const useSections = user => {
  const auth = useAuth();
  const {t} = useTranslation();

  const surveys = {
    title: t("mySurveys"),
    path: paths.dashboard.surveys.list,
    icon: (
      <SvgIcon fontSize="small">
        <BallotIcon />
      </SvgIcon>
    ),
  };

  const stats = {
    title: t("stats.stats"),
    path: paths.superDashboard.stats.index,
    icon: (
      <SvgIcon fontSize="small">
        <BarChartOutlinedIcon />
      </SvgIcon>
    ),
  };

  const newsurvey = {
    title: t("newSurvey"),
    path: "/dashboard/newsurvey",
    icon: (
      <SvgIcon fontSize="small">
        <AddToPhotosIcon />
      </SvgIcon>
    ),
  };

  const findparticipants = {
    title: t("findparticipants"),
    path: "/dashboard/findparticipants",
    icon: (
      <SvgIcon fontSize="small">
        <PersonSearchIcon />
      </SvgIcon>
    ),
  };

  const settingscategory = {
    title: t("settingcategory"),
    path: "/dashboard/settingcategory",
    icon: (
      <SvgIcon fontSize="small">
        <SettingsIcon />
      </SvgIcon>
    ),
  };
  const findParticipantsRequest = {
    title: t("findParticipantsRequest.findParticipantsRequest"),
    path: paths.superDashboard.participantRequests.list,
    icon: (
      <SvgIcon fontSize="small">
        <FactCheckIcon />
      </SvgIcon>
    ),
  };

  const excellToSurvey = {
    title: t("excellToSurvey"),
    path: "/dashboard/excelltosurvey",
    icon: (
      <SvgIcon fontSize="small">
        <UploadFileIcon />
      </SvgIcon>
    ),
  };

  const participantPaymentRequest = {
    title: t("participantPaymentRequests.participantPaymentRequests"),
    path: paths.superDashboard.participantPaymentRequest.list,
    icon: (
      <SvgIcon fontSize="small">
        <RequestQuoteIcon />
      </SvgIcon>
    ),
  };

  const manageParticipantGroups = {
    title: t("manageParticipantGroups.manageParticipantGroups"),
    path: paths.superDashboard.manageParticipantGroups.list,
    icon: (
      <SvgIcon fontSize="small">
        <GroupsIcon />
      </SvgIcon>
    ),
  };

  const manageParticipantRewards = {
    title: t("manageParticipantRewards"),
    path: paths.superDashboard.manageParticipantRewards.list,
    icon: (
      <SvgIcon fontSize="small">
        <CardGiftcardIcon />
      </SvgIcon>
    ),
  };

  const generateLinks = {
    title: t("generateLinks"),
    path: paths.superDashboard.generateLinks.index,
    icon: (
      <SvgIcon fontSize="small">
        <AddLinkIcon />
      </SvgIcon>
    ),
  };

  const manageOutsourceSurveys = {
    title: t("manageOutsourceSurveys"),
    path: paths.superDashboard.manageOutsourceSurveys.list,
    icon: (
      <SvgIcon fontSize="small">
        <WorkspacesIcon />
      </SvgIcon>
    ),
  };

  const inviteParticipant = {
    title: t("inviteParticipant"),
    path: paths.superDashboard.inviteParticipant.index,
    icon: (
      <SvgIcon fontSize="small">
        <FindParticiIcon />
      </SvgIcon>
    ),
  };

  const teams = {
    title: "Teams",
    path: paths.superDashboard.teams.list,
    icon: (
      <SvgIcon fontSize="small">
        <BuildingIcon />
      </SvgIcon>
    ),
  };

  const manageBlacklist = {
    title: "Manage Blacklist",
    path: paths.superDashboard.manageBlacklist.list,
    icon: (
      <SvgIcon fontSize="small">
        <GppBadIcon />
      </SvgIcon>
    ),
  };

  if (auth.user.is_superuser) {
    return [
      surveys,
      newsurvey,
      manageOutsourceSurveys,
      findparticipants,
      settingscategory,
      findParticipantsRequest,
      excellToSurvey,
      participantPaymentRequest,
      manageParticipantGroups,
      manageParticipantRewards,
      generateLinks,
      inviteParticipant,
      stats,
      manageBlacklist,
      teams,
    ];
  }
  return [
    surveys,
    newsurvey,
    findparticipants,
    settingscategory,
    findParticipantsRequest,
    excellToSurvey,
    participantPaymentRequest,
  ];
};
