import {createContext, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {newquestionService} from "src/new_services/survey/question";
import {newpageService} from "src/new_services/survey/page";
import {blockService} from "src/services/project/survey/block";
import {Survey} from "src/entities/project/survey/survey";
import {projectService} from "src/services/project/project";
import {pageService} from "src/services/project/survey/page";
import {flowService} from "src/services/project/survey/flow";
import {Flow} from "src/entities/project/survey/flow";

export const SurveyBuilderContext = createContext({
  survey: null,
  surveyFlow: null,
});

export const SurveyBuilderProvider = props => {
  const {children} = props;
  const {uuid} = useParams();
  const [survey, setSurvey] = useState(null);
  const [surveyFlow, setSurveyFlow] = useState(null);

  useEffect(() => {
    projectService.retrieveProjectSurvey(uuid).then(retrievedSurvey => {
      setSurvey(new Survey(retrievedSurvey));
    });
  }, []);

  const handlePublishSurvey = async _ => {
    await projectService.publishProject(uuid).then(response => {
      survey.is_published = true;
      setSurvey(survey.clone());
    });
  };

  const handleUpdateSurveySettings = async data => {
    await projectService.updateProjectSurvey(survey.id, data).then(updatedSurvey => {
      survey.updateSettings(updatedSurvey);
      setSurvey(survey.clone());
    });
  };

  const handleSetCurrentBlock = block => {
    survey.setCurrentQuestion(null);
    survey.setCurrentBlock(block);
    setSurvey(survey.clone());
  };

  const handleSetCurrentQuestion = question => {
    survey.setCurrentBlock(survey.blocksMap[question.blockId]);
    survey.setCurrentQuestion(question);
    setSurvey(survey.clone());
  };

  /* BLOCK */
  const handleCreateBlock = blockTitle => {
    const data = {
      project_id: uuid,
      title: blockTitle,
    };
    blockService.createBlock(data).then(block => {
      survey.setBlock(block);
      survey.setCurrentBlock(survey.blocksMap[block.id]);
      setSurvey(survey.clone());
    });
  };

  const handleUpdateBlock = async payload => {
    blockService.updateBlock(survey.currentBlock.id, payload).then(block => {
      survey.setBlock(block);
      setSurvey(survey.clone());
    });
  };

  const handleRemoveBlock = blockId => {
    blockService.removeBlock(blockId).then(data => {
      survey.removeBlock(blockId);
      survey.removeBlockQuestions(blockId);
      survey.setCurrentBlock(Object.values(survey.blocksMap)[0]);
      survey.setCurrentQuestion(null);
      setSurvey(survey.clone());
    });
  };

  const handleCreateAllPageBreak = async _ => {
    const blockId = survey.currentBlock.id;
    await blockService.createBlockPages(blockId).then(updatedBlock => {
      updatedBlock.pages.forEach(page => {
        survey.setPage(page, updatedBlock.id);
        page.questions.forEach(question => {
          survey.setQuestion(question, updatedBlock.id, page.id);
        });
      });

      if (updatedBlock.pages.length === 1) {
        const pagesToDelete = Object.values(survey.pagesMap).filter(
          page => page.blockId === blockId && page.id !== updatedBlock.pages[0].id,
        );
        pagesToDelete.forEach(page => {
          survey.removePage(page.id);
        });
      }

      setSurvey(survey.clone());
    });
  };

  const handleUpdateBlockQuestions = async data => {
    const blockId = survey.currentBlock.id;
    await blockService.updateBlockQuestions(blockId, data).then(updatedBlock => {
      updatedBlock.pages.forEach(page => {
        survey.setPage(page, updatedBlock.id);
        page.questions.forEach(question => {
          survey.setQuestion(question, updatedBlock.id, page.id);
        });
      });
      setSurvey(survey.clone());
    });
  };

  const handleCreatePageBreak = async questionId => {
    const data = {
      block_id: survey.questionsMap[questionId].blockId,
      page_id: survey.questionsMap[questionId].pageId,
      question_id: questionId,
    };
    await pageService.createPageBreak(data).then(updatedBlock => {
      updatedBlock.pages.forEach(page => {
        survey.setPage(page, updatedBlock.id);
        page.questions.forEach(question => {
          survey.setQuestion(question, updatedBlock.id, page.id);
        });
      });

      setSurvey(survey.clone());
    });
  };

  const handleRemovePageBreak = async pageId => {
    await pageService.removePageBreak(pageId).then(updatedBlock => {
      updatedBlock.pages.forEach(page => {
        survey.setPage(page, updatedBlock.id);
        page.questions.forEach(question => {
          survey.setQuestion(question, updatedBlock.id, page.id);
        });
      });
      survey.removePage(pageId);

      setSurvey(survey.clone());
    });
  };

  // QUESTION
  const handleCreateQuestion = payload => {
    newpageService.createQuestion(payload).then(pageQuestions => {
      pageQuestions.forEach(question => {
        survey.setQuestion(question, payload.block_id, question.page_id);
        if (question.index === payload.index) {
          survey.setCurrentQuestion(question);
        }
      });
      if (!payload.page_id) {
        const newPage = {id: pageQuestions[0].page_id, index: 0};
        survey.setPage(newPage, payload.block_id);
      }
      setSurvey(survey.clone());
    });
  };

  const updateQuestionRequiredStatus = (questionId, isRequired) => {
    const blockId = survey.questionsMap[questionId].blockId;
    const question = survey.questionsMap[questionId];
    question.is_required = isRequired;
    survey.setQuestion(question, blockId, question.page_id);
    setSurvey(survey.clone());

    return newquestionService.updateQuestion(questionId, {is_required: isRequired});
  };

  const listQuestions = (pageId, blockId) => {
    if (pageId) {
      return Object.values(survey.questionsMap)
        .filter(question => question.pageId === pageId)
        .sort((a, b) => a.index - b.index);
    } else if (blockId) {
      return Object.values(survey.questionsMap).filter(
        question => question.blockId === blockId,
      );
    }
    return Object.values(survey.questionsMap).sort((a, b) => a.index - b.index);
  };

  const handleUpdateQuestion = (questionId, payload) => {
    const blockId = survey.questionsMap[questionId].blockId;
    newquestionService.updateQuestion(questionId, payload).then(question => {
      survey.setQuestion(question, blockId, question.page_id);
      setSurvey(survey.clone());
    });
  };

  const handleCreateDisplayLogicQuestion = (questionId, payload) => {
    const blockId = survey.questionsMap[questionId].blockId;
    newquestionService.createQuestionDisplayLogic(questionId, payload).then(question => {
      survey.setQuestion(question, blockId, question.page_id);
      setSurvey(survey.clone());
    });
  };

  const handleUpdateQuestionType = (questionId, newQuestionType) => {
    const blockId = survey.questionsMap[questionId].blockId;
    const payload = {
      new_type: newQuestionType,
    };
    newquestionService.updateQuestionType(questionId, payload).then(question => {
      survey.setQuestion(question, blockId, question.page_id);
      survey.removeQuestion(questionId);
      survey.setCurrentQuestion(survey.questionsMap[question.id]);
      setSurvey(survey.clone());
    });
  };

  const handleCopyQuestion = questionId => {
    const blockId = survey.questionsMap[questionId].blockId;
    const pageId = survey.questionsMap[questionId].pageId;
    newpageService.copyQuestion(pageId, questionId).then(pageQuestions => {
      pageQuestions.forEach(question => {
        survey.setQuestion(question, blockId, pageId);
      });
      setSurvey(survey.clone());
    });
  };

  const handleRemoveQuestion = questionId => {
    const pageId = survey.questionsMap[questionId].pageId;

    newpageService.removeQuestion(pageId, questionId).then(updatedBlock => {
      updatedBlock.pages.forEach(page => {
        survey.setPage(page, updatedBlock.id);
        page.questions.forEach(question => {
          survey.setQuestion(question, updatedBlock.id, page.id);
        });
      });

      if (!updatedBlock.pages.find(page => page.id === pageId)) {
        survey.removePage(pageId);
      }
      survey.setCurrentQuestion(null);
      survey.removeQuestion(questionId);

      setSurvey(survey.clone());
    });
  };

  // MULTIPLE_CHOICE QUESTION
  const handleCreateMultipleChoiceQuestionOptions = questionId => {
    const blockId = survey.questionsMap[questionId].blockId;
    newquestionService.createMultipleChoiceQuestionOptions(questionId).then(question => {
      survey.setQuestion(question, blockId, question.page_id);
      setSurvey(survey.clone());
    });
  };

  const handleUpdateMultipleChoiceQuestionOptions = (questionId, options) => {
    const blockId = survey.questionsMap[questionId].blockId;
    newquestionService
      .updateMultipleChoiceQuestionOptions(questionId, options)
      .then(question => {
        survey.setQuestion(question, blockId, question.page_id);
        setSurvey(survey.clone());
      });
  };

  const handleDeleteMultipleChoiceQuestionOptions = (questionId, optionId) => {
    const blockId = survey.questionsMap[questionId].blockId;
    newquestionService
      .deleteMultipleChoiceQuestionOptions(questionId, optionId)
      .then(question => {
        survey.setQuestion(question, blockId, question.page_id);
        setSurvey(survey.clone());
      });
  };

  const handleCreateQuestionSkipLogic = (questionId, payload) => {
    const blockId = survey.questionsMap[questionId].blockId;
    newquestionService.createQuestionSkipLogic(questionId, payload).then(question => {
      survey.setQuestion(question, blockId, question.page_id);
      setSurvey(survey.clone());
    });
  };

  const handleRemoveQuestionSkipLogic = (questionId, skipLogicId) => {
    const blockId = survey.questionsMap[questionId].blockId;
    newquestionService.deleteQuestionSkipLogic(questionId, skipLogicId).then(question => {
      survey.setQuestion(question, blockId, question.page_id);
      setSurvey(survey.clone());
    });
  };

  const handleDeleteMultipleChoiceQuestionCarryChoiceOptions = questionId => {
    const blockId = survey.questionsMap[questionId].blockId;
    newquestionService
      .deleteMultipleChoiceQuestionCarryOptions(questionId)
      .then(question => {
        survey.setQuestion(question, blockId, question.page_id);
        setSurvey(survey.clone());
      });
  };

  // FLOW
  const handleFetchSurveyFlow = () => {
    flowService.retrieveSurveyFlow(survey.id).then(responseFlow => {
      setSurveyFlow(new Flow(responseFlow));
    });
  };

  const handleCreateFlowElement = (flowId, data) => {
    flowService.createFlowElement(survey.id, flowId, data).then(responseFlow => {
      setSurveyFlow(new Flow(responseFlow));
    });
  };

  const handleDragAndDropFlowElements = data => {
    flowService.dragAndDropElement(survey.id, data).then(responseFlow => {
      setSurveyFlow(new Flow(responseFlow));
    });
  };

  const handleDeleteFlowElement = elementId => {
    flowService.deleteFlowElement(survey.id, elementId).then(responseFlow => {
      setSurveyFlow(new Flow(responseFlow));
    });
  };

  const handleUpdateBranchCondition = (elementId, data) => {
    flowService.updateBranchCondition(survey.id, elementId, data).then(responseFlow => {
      setSurveyFlow(new Flow(responseFlow));
    });
  };

  return (
    <SurveyBuilderContext.Provider
      value={{
        survey,
        surveyFlow,

        handleSetCurrentBlock,
        handleSetCurrentQuestion,

        // SURVEY
        handleUpdateSurveySettings,
        handleUpdateBlockQuestions,
        handlePublishSurvey,

        // BLOCK
        handleCreateBlock,
        handleUpdateBlock,
        handleRemoveBlock,
        handleCreatePageBreak,
        handleCreateAllPageBreak,
        handleRemovePageBreak,

        // QUESTION
        updateQuestionRequiredStatus,
        listQuestions,
        handleCreateQuestion,
        handleUpdateQuestion,
        handleUpdateQuestionType,
        handleCopyQuestion,
        handleRemoveQuestion,
        handleCreateDisplayLogicQuestion,
        handleCreateMultipleChoiceQuestionOptions,
        handleDeleteMultipleChoiceQuestionCarryChoiceOptions,
        handleUpdateMultipleChoiceQuestionOptions,
        handleDeleteMultipleChoiceQuestionOptions,
        handleCreateQuestionSkipLogic,
        handleRemoveQuestionSkipLogic,

        // FLOW
        handleFetchSurveyFlow,
        handleCreateFlowElement,
        handleDeleteFlowElement,
        handleUpdateBranchCondition,
        handleDragAndDropFlowElements,
      }}
    >
      {children}
    </SurveyBuilderContext.Provider>
  );
};

export const SurveyBuilderConsumer = SurveyBuilderContext.Consumer;
