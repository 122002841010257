import {
  Box,
  Button,
  Card,
  Divider,
  Grid,
  TextField,
  ToggleButton,
  Typography,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import {styled} from "@mui/material/styles";
import ToggleButtonGroup, {
  toggleButtonGroupClasses,
} from "@mui/material/ToggleButtonGroup";
import {superParticipantRequestService} from "src/services/super/project/participant-request";
import {useState} from "react";
import {useTranslation} from "react-i18next";
import {useAuth} from "src/hooks/use-auth";
import {useParams} from "react-router-dom";

const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({theme}) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: 16,
  padding: theme.spacing(1),
  backgroundColor: theme.palette.background.paper,

  [`& .${toggleButtonGroupClasses.grouped}`]: {
    margin: theme.spacing(0.5),
    border: 0,
    borderRadius: theme.shape.borderRadius,
    "&.Mui-selected": {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,

      // Apply hover effect when selected
      "&:hover": {
        backgroundColor: theme.palette.primary.main, // Darker shade for hover
      },
    },
    [`&.${toggleButtonGroupClasses.disabled}`]: {
      border: 0,
    },
  },
}));

const ageRangeMap = {
  "18-24": {min: 18, max: 24},
  "25-34": {min: 25, max: 34},
  "35-44": {min: 35, max: 44},
  "45+": {min: 45, max: null},
};

export const ParticipantAssignmentFilter = props => {
  const {handleNewAssignmentBatch} = props;
  const {t} = useTranslation();
  const {id} = useParams();
  const auth = useAuth();

  const [genders, setGenders] = useState([]);
  const [ageRanges, setAgeRanges] = useState([]);
  const [participantCount, setParticipantCount] = useState(0);
  const [projectStatus, setProjectStatus] = useState("active");
  const [submitLoading, setSubmitLoading] = useState(false);

  const handleGenders = (event, newGenders) => {
    if (newGenders) {
      setGenders(newGenders);
    }
  };

  const handleAgeRanges = (event, newAgeRanges) => {
    if (newAgeRanges) {
      setAgeRanges(newAgeRanges);
    }
  };

  const handleSubmit = () => {
    setSubmitLoading(true);
    const payload = {
      participant_request_id: id,
      participant_count: participantCount,
      status: projectStatus,
      expire_date: null,
      genders: genders,
      ages: ageRanges.map(ageRange => ageRangeMap[ageRange]),
      notifications: [],
    };
    superParticipantRequestService
      .createProjectAssignment(payload)
      .then(response => {
        handleNewAssignmentBatch(response);
        auth.showAlert("success", t("successfullyAssigned"));
        setGenders([]);
        setAgeRanges([]);
        setParticipantCount(0);
        setProjectStatus("active");
      })
      .catch(_ => {
        auth.showAlert("error", t("errorInAssignment"));
      })
      .finally(_ => {
        setSubmitLoading(false);
      });
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xl={6} lg={6} md={6} xs={12}>
          <Card sx={{width: "100%", alignItems: "center", px: 4, py: 2, mb: 4}}>
            <Typography variant="h6" component="div" sx={{py: 1}}>
              {t("findParticipants.selectGender")}
            </Typography>
            <Typography
              variant="body2"
              sx={theme => ({pb: 1, color: theme.palette.grey[500]})}
            >
              {t("findParticipants.pleaseSendGender")}
            </Typography>
            <StyledToggleButtonGroup
              size="small"
              value={genders}
              onChange={handleGenders}
              aria-label="gender alignment"
            >
              <ToggleButton value="Male" aria-label="male" sx={{px: 2}}>
                <Typography fontSize={13} fontWeight="bold">
                  {t("male")}
                </Typography>
              </ToggleButton>

              <Divider flexItem orientation="vertical" sx={{mx: 0.5}} />

              <ToggleButton value="Female" aria-label="female" sx={{px: 2}}>
                <Typography fontSize={13} fontWeight="bold">
                  {t("female")}
                </Typography>
              </ToggleButton>
            </StyledToggleButtonGroup>
          </Card>
        </Grid>
        <Grid item xl={6} lg={6} md={6} xs={12}>
          <Card sx={{width: "100%", alignItems: "center", px: 4, py: 2, mb: 4}}>
            <Typography variant="h6" component="div" sx={{py: 1}}>
              {t("findParticipants.selectAgeGroups")}
            </Typography>
            <Typography
              variant="body2"
              sx={theme => ({pb: 1, color: theme.palette.grey[500]})}
            >
              {t("findParticipants.pleaseSendAge")}
            </Typography>
            <StyledToggleButtonGroup
              size="small"
              value={ageRanges}
              onChange={handleAgeRanges}
              aria-label="ageRanges"
            >
              <ToggleButton value="18-24" aria-label="18-24" sx={{px: 2}}>
                <Typography fontSize={13} fontWeight="bold">
                  18-24
                </Typography>
              </ToggleButton>

              <Divider flexItem orientation="vertical" sx={{mx: 0.5}} />

              <ToggleButton value="25-34" aria-label="25-34" sx={{px: 2}}>
                <Typography fontSize={13} fontWeight="bold">
                  25-34
                </Typography>
              </ToggleButton>

              <Divider flexItem orientation="vertical" sx={{mx: 0.5}} />

              <ToggleButton value="35-44" aria-label="35-44" sx={{px: 2}}>
                <Typography fontSize={13} fontWeight="bold">
                  35-44
                </Typography>
              </ToggleButton>

              <Divider flexItem orientation="vertical" sx={{mx: 0.5}} />

              <ToggleButton value="45+" aria-label="45+" sx={{px: 2}}>
                <Typography fontSize={13} fontWeight="bold">
                  45+
                </Typography>
              </ToggleButton>
            </StyledToggleButtonGroup>
          </Card>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        {/* TARGETED NUMBER OF PARTICIPANT */}
        <Grid item xl={6} lg={6} md={6} xs={12}>
          <Card sx={{width: "100%", alignItems: "center", px: 4, py: 2, mb: 4}}>
            <Typography variant="h6" component="div" sx={{py: 1}}>
              {t("sendParticipant.targetNumberOfParticipants")}
            </Typography>
            <TextField
              fullWidth
              label={t("sendParticipant.targetNumberOfParticipants")}
              variant="outlined"
              value={participantCount}
              onChange={e => setParticipantCount(e.target.value)}
              type="number"
            />
          </Card>
        </Grid>

        {/* PROJECT STATUS */}
        <Grid item xl={6} lg={6} md={6} xs={12}>
          <Card sx={{width: "100%", alignItems: "center", px: 4, py: 2, mb: 4}}>
            <Typography variant="h6" component="div" sx={{py: 1}}>
              Project Status
            </Typography>
            <StyledToggleButtonGroup
              size="small"
              value={projectStatus}
              onChange={(e, newStatus) => newStatus && setProjectStatus(newStatus)}
              aria-label="project status alignment"
              exclusive={true}
            >
              <ToggleButton value="soon" aria-label="soon" sx={{px: 2}}>
                <Typography fontSize={13} fontWeight="bold">
                  Coming Soon
                </Typography>
              </ToggleButton>

              <Divider flexItem orientation="vertical" sx={{mx: 0.5}} />

              <ToggleButton value="active" aria-label="active" sx={{px: 2}}>
                <Typography fontSize={13} fontWeight="bold">
                  Active
                </Typography>
              </ToggleButton>
            </StyledToggleButtonGroup>
          </Card>
        </Grid>
      </Grid>

      <Box sx={{display: "flex", justifyContent: "flex-end", marginTop: 2}}>
        <Button
          variant="contained"
          size="large"
          onClick={handleSubmit}
          // disabled={!isFormValid()} // Disable button if form is not valid
        >
          {submitLoading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            t("findParticipantsRequest.sendParticipant")
          )}
        </Button>
      </Box>
    </>
  );
};
