import {DashboardLayout} from "src/layouts/dashboard/dashboard-layout";
import {lazy, Suspense} from "react";
import {Outlet} from "react-router-dom";
import {ParticipantRequests} from "src/pages/dashboard/super/participant-requests";
import {ParticipantRequestDetail} from "src/pages/dashboard/super/participant-requests/participant-request-detail";
import {ParticipantsPaymentRequests} from "src/pages/dashboard/super/participant-payment-request";
import {ParticipantPaymentRequestDetail} from "src/pages/dashboard/super/participant-payment-request/participant-payment-request-detail";
import {ManageParticipantGroups} from "src/pages/dashboard/super/manage-participant-groups";
import {ManageParticipantRewards} from "src/pages/dashboard/super/manage-participant-rewards";
import {GenerateLinks} from "src/pages/dashboard/super/generate-links";
import {ManageOutsourceSurveys} from "src/pages/dashboard/super/manage-outsource-surveys";
import {DistributeOutsourceSurvey} from "src/pages/dashboard/super/manage-outsource-surveys/distribute-outsource-survey";
import {InviteParticipant} from "src/pages/dashboard/super/invite-participant";
import TeamDetails from "src/pages/admin/teams/team-details";
import {TeamMemberDetails} from "src/pages/admin/teams/team-member-details";
import {Stats} from "src/pages/dashboard/super/stats";

const TeamListPage = lazy(() => import("src/pages/admin/teams"));
const ManageBlacklistPage = lazy(
  () => import("src/pages/dashboard/super/manage-blacklist"),
);

export const superDashboardRoutes = [
  {
    path: "super/dashboard",
    element: (
      <DashboardLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      {
        path: "findpartrequests",
        children: [
          {
            index: true,
            element: <ParticipantRequests />,
          },
          {
            path: ":id",
            element: <ParticipantRequestDetail />,
          },
        ],
      },
      {
        path: "participant-payment-request",
        children: [
          {
            index: true,
            element: <ParticipantsPaymentRequests />,
          },
          {
            path: ":id",
            element: <ParticipantPaymentRequestDetail />,
          },
        ],
      },
      {
        path: "manage-participant-groups",
        children: [
          {
            index: true,
            element: <ManageParticipantGroups />,
          },
        ],
      },
      {
        path: "manage-participant-rewards",
        children: [
          {
            index: true,
            element: <ManageParticipantRewards />,
          },
        ],
      },
      {
        path: "generate-links",
        children: [
          {
            index: true,
            element: <GenerateLinks />,
          },
        ],
      },
      {
        path: "manage-outsource-surveys",
        children: [
          {
            index: true,
            element: <ManageOutsourceSurveys />,
          },
          {
            path: ":id",
            element: <DistributeOutsourceSurvey />,
          },
        ],
      },
      {
        path: "invite-participant",
        children: [
          {
            index: true,
            element: <InviteParticipant />,
          },
        ],
      },
      {
        path: "manage-blacklist",
        children: [
          {
            index: true,
            element: <ManageBlacklistPage />,
          },
        ],
      },
      {
        path: "teams",
        children: [
          {
            index: true,
            element: <TeamListPage />,
          },
          {
            path: ":id",
            children: [
              {
                index: true,
                element: <TeamDetails />,
              },
              {
                path: "members",
                children: [
                  {
                    path: ":teamMemberId",
                    element: <TeamMemberDetails />,
                  },
                ],
              },
            ],
          },
        ],
      },
      {
        path: "stats",
        children: [
          {
            index: true,
            element: <Stats />,
          },
        ],
      },
    ],
  },
];
