import axios from "axios";

const baseUrl = process.env.REACT_APP_API;

export const ACCESS_TOKEN_KEY = "accessToken";
export const ACCESS_TOKEN_EXPIRATION_KEY = "accessExpiration";
export const CSRF_TOKEN_KEY = "csrfToken";

const axiosInstance = axios.create({
  baseURL: baseUrl,
  withCredentials: true, // Set withCredentials for all requests made through this instance
});

axiosInstance.interceptors.request.use(function (config) {
  const token = localStorage.getItem(ACCESS_TOKEN_KEY);
  const csrf_token = localStorage.getItem(CSRF_TOKEN_KEY);

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }
  if (csrf_token) {
    config.headers["X-CSRFToken"] = csrf_token;
  }

  // config.headers["Cache-Control"] = "no-cache";
  return config;
});

export default axiosInstance;
