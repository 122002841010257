export class Participant {
  constructor(response) {
    this.id = response.id;
    this.name = response.name;
    this.email = response.email;
    this.phone = response.phone;
    this.birth_date = response.birth_date;
    this.country = response.country;
    this.gender = response.gender;
    this.province = response.province;
  }
}

export class ParticipantGroup {
  constructor(response) {
    this.id = response.id;
    this.name = response.name;
    this.member_count = response.member_count;
    this.status = response.status;
  }
}
