import {Container} from "@mui/system";
import {TeamMemberInvitation} from "src/pages/dashboard/super/generate-links/team-member-invitation";
import {PasswordReset} from "src/pages/dashboard/super/generate-links/password-reset";

export const GenerateLinks = () => {
  return (
    <Container maxWidth="lg">
      <TeamMemberInvitation />
      <PasswordReset />
    </Container>
  );
};
