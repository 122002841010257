import React, {useEffect, useState} from "react";
import {Paper, Typography, Grid} from "@mui/material";
import {t} from "i18next";
import {superStatsService} from "src/services/super/project/stats";
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  Legend,
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";

export const ParticipantStats = () => {
  const [participantStats, setparticipantStats] = useState(null);

  useEffect(() => {
    superStatsService
      .getParticipantStats()
      .then(response => {
        if (response) {
          setparticipantStats(response);
        } else {
          console.error("Invalid response structure:", response);
        }
      })
      .catch(error => {
        console.error("Error fetching approved reward stats:", error);
      });
  }, []);

  const genderData = participantStats
    ? participantStats.gender_stats.map(item => ({
        name:
          item.gender === 1
            ? t("stats.male")
            : item.gender === 2
              ? t("stats.female")
              : t("stats.other"),
        value: item.count,
      }))
    : [];

  const provinceData = participantStats
    ? [...participantStats.province_stats]
        .sort((a, b) => b.count - a.count)
        .map(item => ({
          name: item.bank_province || t("stats.noData"),
          count: item.count,
        }))
    : [];

  const countryData = participantStats
    ? [...participantStats.country_stats]
        .sort((a, b) => b.count - a.count)
        .map(item => ({
          name: item.country || t("stats.noData"),
          count: item.count,
        }))
    : [];

  const ageData = participantStats
    ? participantStats.age_stats.map(item => ({
        name: item.age,
        count: item.count,
      }))
    : [];

  const GENDER_COLORS = {
    male: "#67aada",
    female: "#e3abc8",
    other: "#ffba7b",
  };

  const CHART_COLORS = {
    province: "#982a46",
    country: "#cfac58",
    age: "#2a3463",
  };

  return (
    <div style={{margin: 16}}>
      <Paper
        elevation={2}
        sx={{
          padding: 2,
          borderRadius: 2,
          backgroundColor: "#f9f9f9",
          width: "150%",
          margin: "auto",
        }}
      >
        <Typography variant="h5" color="textPrimary" gutterBottom align="center">
          {t("stats.participantStats")}
        </Typography>
        <Grid container spacing={3}>
          {/* Gender PieChart */}
          <Grid item xs={12} sm={6} md={6} mt={5}>
            <Paper style={{padding: 16}}>
              <Typography
                variant="h6"
                color="textSecondary"
                align="center"
                gutterBottom
              ></Typography>
              <Typography variant="h6" color="textSecondary" align="center" gutterBottom>
                {t("stats.genderDistribution")}
              </Typography>
              <ResponsiveContainer width="100%" height={350}>
                <PieChart>
                  <Pie
                    data={genderData}
                    dataKey="value"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    outerRadius={100}
                    fill="#8884d8"
                    label
                  >
                    {genderData.map((entry, index) => (
                      <Cell
                        key={`cell-${index}`}
                        fill={
                          entry.name === t("stats.male")
                            ? GENDER_COLORS.male
                            : entry.name === t("stats.female")
                              ? GENDER_COLORS.female
                              : GENDER_COLORS.other
                        }
                      />
                    ))}
                  </Pie>
                  <Tooltip />
                  <Legend />
                </PieChart>
              </ResponsiveContainer>
            </Paper>
          </Grid>

          {/* Province BarChart */}
          <Grid item xs={12} sm={6} md={6} mt={5}>
            <Paper style={{padding: 16}}>
              <Typography
                variant="h6"
                color="textSecondary"
                align="center"
                gutterBottom
              ></Typography>
              <Typography variant="h6" color="textSecondary" align="center" gutterBottom>
                {t("stats.provinceDistribution")}
              </Typography>
              <ResponsiveContainer width="100%" height={350}>
                <BarChart data={provinceData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    label={{
                      value: t("stats.province"),
                      position: "insideBottom",
                      offset: 10,
                      style: {textAnchor: "middle", padding: 10},
                    }}
                    height={80}
                    tick={{
                      angle: -45,
                      textAnchor: "end",
                    }}
                    interval={0}
                    dataKey="name"
                  />
                  <YAxis
                    label={{
                      value: t("stats.participantCount"),
                      angle: -90,
                      position: "insideLeft",
                      style: {textAnchor: "middle"},
                    }}
                    tickFormatter={value => (Number.isInteger(value) ? value : "")}
                  />
                  <Tooltip />
                  <Bar dataKey="count" fill={CHART_COLORS.province} />
                </BarChart>
              </ResponsiveContainer>
            </Paper>
          </Grid>

          {/* Country BarChart */}
          <Grid item xs={12} sm={6} md={6}>
            <Paper style={{padding: 16}}>
              <Typography
                variant="h6"
                color="textSecondary"
                align="center"
                gutterBottom
              ></Typography>
              <Typography variant="h6" color="textSecondary" align="center" gutterBottom>
                {t("stats.countryDistribution")}
              </Typography>
              <ResponsiveContainer width="100%" height={350}>
                <BarChart data={countryData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    label={{
                      value: t("stats.country"),
                      position: "insideBottom",
                      offset: 10,
                      style: {textAnchor: "middle", padding: 10},
                    }}
                    height={80}
                    tick={{
                      angle: -45,
                      textAnchor: "end",
                    }}
                    dataKey="name"
                  />
                  <YAxis
                    label={{
                      value: t("stats.participantCount"),
                      angle: -90,
                      position: "insideLeft",
                      style: {textAnchor: "middle"},
                    }}
                    tickFormatter={value => (Number.isInteger(value) ? value : "")}
                  />
                  <Tooltip />
                  <Bar dataKey="count" fill={CHART_COLORS.country} />
                </BarChart>
              </ResponsiveContainer>
            </Paper>
          </Grid>

          {/* Age BarChart */}
          <Grid item xs={12} sm={6} md={6}>
            <Paper style={{padding: 16}}>
              <Typography
                variant="h6"
                color="textSecondary"
                align="center"
                gutterBottom
              ></Typography>
              <Typography variant="h6" color="textSecondary" align="center" gutterBottom>
                {t("stats.ageDistribution")}
              </Typography>
              <ResponsiveContainer width="100%" height={350}>
                <BarChart data={ageData}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis
                    label={{
                      value: t("stats.age"),
                      position: "insideBottom",
                      offset: 10,
                      style: {textAnchor: "middle", padding: 10},
                    }}
                    height={80}
                    tick={{
                      angle: -45,
                      textAnchor: "end",
                    }}
                    dataKey="name"
                  />
                  <YAxis
                    label={{
                      value: t("stats.participantCount"),
                      angle: -90,
                      position: "insideLeft",
                      style: {textAnchor: "middle"},
                    }}
                    tickFormatter={value => (Number.isInteger(value) ? value : "")}
                  />
                  <Tooltip />
                  <Bar dataKey="count" fill={CHART_COLORS.age} />
                </BarChart>
              </ResponsiveContainer>
            </Paper>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};

export default ParticipantStats;
