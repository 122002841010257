import {RewardType} from "src/schemas/reward";

export class RewardRequest {
  constructor(response) {
    this.id = response.id;
    this.completed_survey_count = response.completed_project_count;
    this.status = response.status;
    this.email = response.email;
    this.phone = response.phone;
    this.participant_id = response.participant_id;
    this.amount = response.amount;
    this.type = response.type;
    this.is_flagged = response.is_flagged;
    this.created_at = response.created_at;
    this.updated_at = response.updated_at;
  }
}

export class RewardRequestDetail {
  constructor(response) {
    this.id = response.id;
    this.team_member = {
      id: response.team_member.id,
      team_id: response.team_member.team_id,
    };
    this.completed_survey_count = response.completed_survey_count;
    this.average_submission_duration = response.average_submission_duration;
    this.status = response.status;
    this.contact = response.email ?? response.phone;
    this.participant_id = response.participant_id;
    this.amount =
      response.amount - (response.tax_amount ?? 0) + (response.gift_amount ?? 0);
    this.type = response.type;
    this.code = "";
    this.pin = "";
    this.email = response.email;
    this.phone = response.phone;
    this.created_at = response.created_at;
    this.updated_at = response.updated_at;
    this.message = response.message;
    if (response.code) {
      if (response.type === RewardType.A101) {
        const reward_code = response.code.split("|");
        this.code = reward_code[0];
        this.pin = reward_code[1];
      } else if (response.type === RewardType.MIGROS) {
        this.code = response.code;
      }
    }
    this.bank_details = {
      bank_full_name: response.bank_details.bank_full_name,
      bank_name: response.bank_details.bank_name,
      bank_iban: response.bank_details.bank_iban,
      bank_identity_number: response.bank_details.bank_identity_number,
      bank_address: response.bank_details.bank_address,
      bank_province: response.bank_details.bank_province,
      bank_neighborhood: response.bank_details.bank_neighborhood,
      bank_street: response.bank_details.bank_street,
      bank_building: response.bank_details.bank_building,
      bank_postal_code: response.bank_details.bank_postal_code,
    };
  }
}

export class TeamMemberRewardsStats {
  constructor(stats) {
    this.available_reward_amount = stats.available_reward_amount;
    this.pending_reward_amount = stats.pending_reward_amount;
    this.used_reward_amount = stats.used_reward_amount;
  }
}
