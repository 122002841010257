import {Container, Grid, Paper, Box, Button, Typography} from "@mui/material";
import {CompletedProjects} from "src/pages/admin/teams/team-member-details/completed-projects";
import {Rewards} from "src/pages/admin/teams/team-member-details/rewards";
import {MemberInformation} from "src/pages/admin/teams/team-member-details/member-information";
import {useNavigate, useParams} from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

export const TeamMemberDetails = props => {
  const navigate = useNavigate();

  return (
    <Container maxWidth="xl">
      <Box sx={{mb: 3, mt: 2}}>
        <Button
          onClick={() => navigate(-1)}
          startIcon={<ArrowBackIcon />}
          color="primary"
        >
          Back
        </Button>
      </Box>

      <Grid container spacing={3}>
        <Grid item xs={12} md={8}>
          <Grid container spacing={3}>
            {/* Completed Projects */}
            <Grid item xs={12}>
              <Paper sx={{p: 3}}>
                <CompletedProjects />
              </Paper>
            </Grid>

            {/* Rewards */}
            <Grid item xs={12}>
              <Paper sx={{p: 3}}>
                <Rewards />
              </Paper>
            </Grid>
          </Grid>
        </Grid>

        {/* Member Information */}
        <Grid item xs={12} md={4}>
          <Paper sx={{p: 3, height: "100%"}}>
            <MemberInformation />
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
};
