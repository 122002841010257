import React, {useEffect, useState} from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Container,
  IconButton,
  Box,
  Tabs,
  Tab,
  Skeleton,
  Button,
  Tooltip,
} from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {paths} from "src/pages/paths";
import {superRewardService} from "src/services/super/project/reward";
import {RewardRequest} from "src/entities/project/reward-request";
import {isMobile} from "react-device-detect";
import GenericCard from "src/components/generic/GenericCard";
import {LoadingButton} from "@mui/lab";
import FlagIcon from "@mui/icons-material/Flag";
import {useAuth} from "src/hooks/use-auth";
import {datetimeToObject} from "src/utils/datetime";
import GenericTablePagination from "src/components/generic/GenericTablePagination";
import FilterModal, {defaultRewardsFilters} from "./FilterModal";

export const ParticipantsPaymentRequests = () => {
  const {t} = useTranslation();
  const [paymentRequests, setPaymentRequests] = useState([]);
  const [paymentRequestCount, setPaymentRequestCount] = useState(0);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [activeTab, setActiveTab] = useState("pending");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [exporting, setExporting] = useState(false);
  const auth = useAuth();

  const [filterModalVisible, setFilterModalVisible] = useState(false);
  const [selectedFilter, setSelectedFilter] = useState(defaultRewardsFilters);

  const fetchRewardRequests = _page => {
    setLoading(true);
    let isFlagged = activeTab === "flagged";
    if (!isFlagged) {
      isFlagged = selectedFilter?.includeFlagged ? null : false;
    }
    const statusFilter = activeTab === "flagged" ? null : activeTab;

    const {includeFlagged, ...otherFilters} = selectedFilter || {};

    superRewardService
      .listRewardRequests(
        _page + 1,
        statusFilter,
        isFlagged,
        pageSize,
        null,
        otherFilters,
      )
      .then(response => {
        const results = response.results.map(res => new RewardRequest(res));
        setPaymentRequests(results);
        setPaymentRequestCount(response.count);
        setPage(_page);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchRewardRequests(page);
  }, [activeTab, selectedFilter, pageSize]);

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    setPage(0);
  };

  const handleFlagClick = id => {
    setLoading(true);

    superRewardService
      .flagReward(id)
      .then(response => {
        fetchRewardRequests(page);
        auth.showAlert("success", t("successfullyUpdated"));
      })
      .catch(error => {
        auth.showAlert("error", t("updateFailed"));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleUnFlagClick = id => {
    setLoading(true);

    superRewardService
      .unflagReward(id)
      .then(response => {
        fetchRewardRequests(page);
        auth.showAlert("success", t("successfullyUpdated"));
      })
      .catch(error => {
        auth.showAlert("error", t("updateFailed"));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleExport = async () => {
    setExporting(true);
    let isFlagged = activeTab === "flagged";
    if (!isFlagged) {
      isFlagged = selectedFilter?.includeFlagged ? null : false;
    }
    const statusFilter = activeTab === "flagged" ? null : activeTab;

    const {includeFlagged, ...otherFilters} = selectedFilter || {};

    try {
      const response = await superRewardService.exportRewardRequests(
        statusFilter,
        isFlagged,
        otherFilters,
      );
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `reward_requests.csv`); // Specify the file name
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error("Export failed:", error);
    } finally {
      setExporting(false);
    }
  };

  const renderTableBody = () => {
    if (loading) {
      return Array.from(new Array(5)).map((_, index) => (
        <TableRow key={index}>
          <TableCell>
            <Skeleton variant="text" width={100} />
          </TableCell>
          <TableCell>
            <Skeleton variant="text" width={200} />
          </TableCell>
          <TableCell>
            <Skeleton variant="text" width={200} />
          </TableCell>
          <TableCell>
            <Skeleton variant="text" width={80} />
          </TableCell>
          <TableCell>
            <Skeleton variant="text" width={100} />
          </TableCell>
          <TableCell>
            <Skeleton variant="circular" width={24} height={24} />
          </TableCell>
        </TableRow>
      ));
    }

    return paymentRequests.map(paymentRequest => (
      <TableRow key={paymentRequest.id} hover style={{cursor: "pointer"}}>
        <TableCell>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              position: "relative",
            }}
          >
            <Tooltip title="Flagle" arrow>
              <IconButton
                onClick={() =>
                  paymentRequest.is_flagged
                    ? handleUnFlagClick(paymentRequest.id)
                    : handleFlagClick(paymentRequest.id)
                }
                sx={{
                  visibility: "visible",
                  color: paymentRequest.is_flagged ? "red" : "gray",
                }}
              >
                <FlagIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          </Box>
        </TableCell>
        <TableCell>
          <Box sx={{display: "flex", alignItems: "center"}}>
            <FiberManualRecordIcon
              fontSize="small"
              // Duruma göre renk ayarı
              color={
                paymentRequest.status === "approved"
                  ? "success"
                  : paymentRequest.status === "pending"
                    ? "warning"
                    : "error" // rejected için kırmızı
              }
              sx={{marginRight: 1}}
            />
            {paymentRequest.status}
          </Box>
        </TableCell>
        <TableCell>{paymentRequest.email}</TableCell>
        <TableCell>{paymentRequest.phone}</TableCell>
        <TableCell>{paymentRequest.participant_id}</TableCell>
        <TableCell>{paymentRequest.completed_survey_count}</TableCell>
        <TableCell>{paymentRequest.amount + (paymentRequest.gift_amount ?? 0)}</TableCell>
        <TableCell>{paymentRequest.type}</TableCell>
        <TableCell>{datetimeToObject(paymentRequest.created_at)}</TableCell>
        <TableCell>{datetimeToObject(paymentRequest.updated_at)}</TableCell>
        <TableCell>
          <IconButton
            onClick={() =>
              navigate(
                paths.superDashboard.participantPaymentRequest.details.replace(
                  ":id",
                  paymentRequest.id,
                ),
              )
            }
          >
            <ArrowForwardIosIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    ));
  };

  return (
    <Container maxWidth="lg">
      <FilterModal
        open={filterModalVisible}
        onClose={() => {
          setFilterModalVisible(false);
        }}
        onApply={filters => {
          setPage(0);
          setSelectedFilter(filters);
          console.log(JSON.stringify(filters));
        }}
        activeTab={activeTab}
        selectedFilter={selectedFilter}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: {xs: "column", sm: "row"},
          justifyContent: "space-between",
          alignItems: {xs: "flex-start", sm: "center"},
          marginTop: 4,
          marginBottom: 3,
          gap: 2,
        }}
      >
        <Typography variant="h4" sx={{width: "100%"}}>
          {t("participantPaymentRequests.participantPaymentRequests")}
        </Typography>

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: 1,
            flexDirection: "row",
            width: {xs: "100%", sm: "auto"},
          }}
        >
          <LoadingButton
            loading={exporting}
            startIcon={<FileDownloadIcon />}
            onClick={handleExport}
            type="submit"
            variant="contained"
          >
            Export
          </LoadingButton>

          <LoadingButton
            loading={exporting}
            onClick={() => setFilterModalVisible(true)}
            type="submit"
            variant="contained"
          >
            Filter
          </LoadingButton>
        </Box>
      </Box>

      <Box sx={{borderBottom: 1, borderColor: "divider", mb: 2}}>
        <Tabs value={activeTab} onChange={handleTabChange}>
          <Tab label={t("participantPaymentRequests.pending")} value="pending" />
          <Tab label={t("participantPaymentRequests.approved")} value="approved" />
          <Tab label={t("participantPaymentRequests.cancelled")} value="cancelled" />
          <Tab label={t("participantPaymentRequests.rejected")} value="rejected" />
          <Tab label={t("participantPaymentRequests.flagged")} value="flagged" />
        </Tabs>
      </Box>

      {isMobile ? (
        paymentRequests.map(paymentRequest => (
          <GenericCard
            key={paymentRequest.id}
            data={{
              unExtendedData: [
                {
                  title: t("participantPaymentRequests.flagle"),
                  specialDescription: (
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        position: "relative",
                      }}
                    >
                      <Tooltip title={t("participantPaymentRequests.flagle")} arrow>
                        <IconButton
                          onClick={() =>
                            paymentRequest.is_flagged
                              ? handleUnFlagClick(paymentRequest.id)
                              : handleFlagClick(paymentRequest.id)
                          }
                          sx={{
                            visibility: "visible",
                            color: paymentRequest.is_flagged ? "red" : "gray",
                          }}
                        >
                          <FlagIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  ),
                },
                {
                  title: t("participantPaymentRequests.requestedStatus"),
                  specialDescription: (
                    <Box sx={{display: "flex", alignItems: "center"}}>
                      <FiberManualRecordIcon
                        fontSize="small"
                        // Duruma göre renk ayarı
                        color={
                          paymentRequest.status === "approved"
                            ? "success"
                            : paymentRequest.status === "pending"
                              ? "warning"
                              : "error" // rejected için kırmızı
                        }
                        sx={{marginRight: 1}}
                      />
                      {paymentRequest.status}
                    </Box>
                  ),
                },
                {
                  title: t("participantPaymentRequests.participantEmail"),
                  description: paymentRequest.email,
                },
                {
                  title: t("participantPaymentRequests.participantPhone"),
                  description: paymentRequest.phone,
                },
                {
                  title: t("participantPaymentRequests.participantId"),
                  description: paymentRequest.participant_id,
                },
                {
                  title: t("participantPaymentRequests.completedSurveyCount"),
                  description: paymentRequest.completed_survey_count,
                },
                {
                  title: t("participantPaymentRequests.requestedAmount"),
                  description: paymentRequest.amount,
                },
                {
                  title: t("participantPaymentRequests.requestedMarket"),
                  description: paymentRequest.type,
                },
                {
                  title: t("participantPaymentRequests.createdTime"),
                  description: paymentRequest.created_at,
                },
                {
                  title: t("participantPaymentRequests.updatedTime"),
                  description: paymentRequest.updated_at,
                },
                // {
                //   title: t(
                //     "participantPaymentRequests.participantCompletedSurveyDuration",
                //   ),
                //   description: "",
                // },
                {
                  specialChildren: (
                    <Button
                      onClick={() =>
                        navigate(
                          paths.superDashboard.participantPaymentRequest.details.replace(
                            ":id",
                            paymentRequest.id,
                          ),
                        )
                      }
                      variant="contained"
                      color="primary"
                      endIcon={<ArrowForwardIosIcon />}
                      sx={{
                        mt: 2,
                        ml: "auto",
                        textTransform: "none",
                        fontWeight: "bold",
                        padding: "8px 16px",
                      }}
                    >
                      {t("goToDetail")}
                    </Button>
                  ),
                },
              ],
            }}
          />
        ))
      ) : (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{t("participantPaymentRequests.flagle")}</TableCell>
                <TableCell>{t("participantPaymentRequests.requestedStatus")}</TableCell>
                <TableCell>{t("participantPaymentRequests.participantEmail")}</TableCell>
                <TableCell>{t("participantPaymentRequests.participantPhone")}</TableCell>
                <TableCell>{t("participantPaymentRequests.participantId")}</TableCell>
                <TableCell>
                  {t("participantPaymentRequests.completedSurveyCount")}
                </TableCell>
                <TableCell>{t("participantPaymentRequests.requestedAmount")}</TableCell>
                <TableCell>{t("participantPaymentRequests.requestedMarket")}</TableCell>
                <TableCell>{t("participantPaymentRequests.createdTime")}</TableCell>
                <TableCell>{t("participantPaymentRequests.updatedTime")}</TableCell>
                {/* <TableCell>
                  {t("participantPaymentRequests.participantCompletedSurveyDuration")}
                </TableCell> */}
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>{renderTableBody()}</TableBody>
          </Table>
        </TableContainer>
      )}
      <GenericTablePagination
        count={paymentRequestCount}
        onPageChange={newPage => fetchRewardRequests(newPage)}
        page={page}
        rowsPerPage={pageSize}
        onRowsPerPageChange={newPageSize => {
          setPage(0);
          setPageSize(newPageSize);
        }}
      />
    </Container>
  );
};
