import {Paper, TableContainer, Typography, Box, Container, Stack} from "@mui/material";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import {superTeamService} from "src/services/super/team";
import {useParams} from "react-router-dom";
import GenericTablePagination from "src/components/generic/GenericTablePagination";
import {datetimeToObject} from "src/utils/datetime";
import {RewardRequest} from "src/entities/project/reward-request";
import {RewardRequestDetail} from "src/entities/project/reward-request";
import {superRewardService} from "src/services/super/project/reward";

export const CompletedProjects = props => {
  const {t} = useTranslation();
  const {id, teamMemberId} = useParams();
  // Projects Table
  const [projects, setProjects] = useState([]);
  const [rewardProjectTablePage, setRewardProjectTablePage] = useState(1);
  const [totalRewardProjectCount, setTotalRewardProjectCount] = useState(0);

  const [rewardRequestDetail, setRewardRequestDetail] = useState();

  const fetchRewardProjects = _page => {
    superTeamService.listTeamMemberProjects(id, teamMemberId, _page).then(response => {
      setTotalRewardProjectCount(response.count);
      setProjects(response.results);
      setRewardProjectTablePage(_page);
    });
  };

  const fetchRewardRequestDetails = _ => {
    // FIXME: need new api endpoint to get details
    superRewardService
      .listRewardRequests(1, null, null, 1, teamMemberId)
      .then(response => {
        const results = response.results.map(res => new RewardRequest(res));
        if (results.length > 0) {
          superRewardService
            .getRewardRequestDetails(results[0].id)
            .then(response => setRewardRequestDetail(response));
        }
      });
  };

  useEffect(() => {
    fetchRewardProjects(rewardProjectTablePage);
    fetchRewardRequestDetails();
  }, []);

  return (
    <>
      <Typography
        variant="h5"
        sx={{marginBottom: 4, marginTop: 0, textAlign: "left", fontWeight: "bold"}}
      >
        <Box
          sx={{
            mb: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h5">{t("events.completedEvents")}</Typography>
          <Stack direction="row" spacing={2}>
            <Paper
              elevation={2}
              sx={{
                padding: 2,
                borderRadius: 2,
                textAlign: "center",
                backgroundColor: "#f9f9f9",
                minWidth: 120,
                maxWidth: 159,
              }}
            >
              <Typography variant="subtitle2" color="textSecondary" sx={{mb: 1}}>
                {t("events.averageCompletionTime")}
              </Typography>
              <Typography variant="h6">
                {rewardRequestDetail?.average_submission_duration ?? "-"}
              </Typography>
            </Paper>
            <Paper
              elevation={2}
              sx={{
                padding: 2,
                borderRadius: 2,
                textAlign: "center",
                backgroundColor: "#f9f9f9",
                minWidth: 120,
                maxWidth: 159,
              }}
            >
              <Typography variant="subtitle2" color="textSecondary" sx={{mb: 1}}>
                {t("events.completedSurveyCount")}
              </Typography>
              <Typography variant="h6">
                {rewardRequestDetail?.completed_survey_count ?? "-"}
              </Typography>
            </Paper>
          </Stack>
        </Box>
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>{t("events.surveyName")}</TableCell>
              <TableCell>{t("events.rewardAmount")}</TableCell>
              <TableCell>{t("events.rewardStatus")}</TableCell>
              <TableCell>{t("events.completedAt")}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {projects.map(project => (
              <TableRow key={project.id}>
                <TableCell>{project.topic}</TableCell>
                <TableCell>{project.reward}</TableCell>
                <TableCell>
                  {project.reward_status ? t("events.defined") : t("events.undefined")}
                </TableCell>
                <TableCell>{datetimeToObject(project.completed_at)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <GenericTablePagination
        page={rewardProjectTablePage - 1}
        rowsPerPage={10}
        count={totalRewardProjectCount}
        onPageChange={newPage => {
          fetchRewardProjects(newPage + 1);
        }}
        rowsPerPageOptions={[10]}
      />
    </>
  );
};
