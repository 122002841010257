import {Drawer, Box, Typography, TextField, Button} from "@mui/material";
import {useState, useEffect} from "react";

export const defaultTeamDetailFilters = {
  phone: "",
  email: "",
  team_member_id: "",
};

const FilterModal = ({open, onClose, onApply, selectedFilter}) => {
  const [filters, setFilters] = useState(defaultTeamDetailFilters);

  useEffect(() => {
    setFilters(selectedFilter || defaultTeamDetailFilters);
  }, [selectedFilter]);

  const handleChange = (field, value) => {
    setFilters(prev => ({...prev, [field]: value}));
  };

  const handleApply = () => {
    onApply(filters);
    onClose();
  };

  const handleClear = () => {
    setFilters(defaultTeamDetailFilters);
    onApply(defaultTeamDetailFilters);
    onClose();
  };

  const isFilterChanged =
    JSON.stringify(filters) !== JSON.stringify(defaultTeamDetailFilters);

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      sx={{
        "& .MuiDrawer-paper": {
          maxWidth: 500,
          width: {xs: "90%", sm: "60%", md: "50%"},
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        },
      }}
    >
      <Typography variant="h6" mb={2}>
        Filter Options
      </Typography>

      <TextField
        label="Team Member Id"
        value={filters.team_member_id}
        onChange={e => handleChange("team_member_id", e.target.value)}
        fullWidth
        sx={{mb: 2}}
      />
      <TextField
        label="Phone"
        value={filters.phone}
        onChange={e => handleChange("phone", e.target.value)}
        fullWidth
        sx={{mb: 2}}
      />
      <TextField
        label="Email"
        value={filters.email}
        onChange={e => handleChange("email", e.target.value)}
        fullWidth
        sx={{mb: 2}}
      />

      <Box display="flex" justifyContent="space-between" mt={3}>
        <Button disabled={!isFilterChanged} onClick={handleClear}>
          Clear Filter
        </Button>

        <Box display="flex" gap={2}>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <Button variant="contained" onClick={handleApply}>
            Apply
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};

export default FilterModal;
