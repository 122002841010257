import {useState, useEffect} from "react";
import {useParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {styled} from "@mui/material/styles";
import {
  Button,
  TextField,
  Typography,
  Box,
  Paper,
  Container,
  Grid,
  Card,
  Divider,
  ToggleButton,
  SvgIcon,
  Link,
} from "@mui/material";
import {
  Person as PersonIcon,
  Group as GroupIcon,
  Event as EventIcon,
} from "@mui/icons-material";
import {superParticipantRequestService} from "src/services/super/project/participant-request";
import {ParticipantRequestDetails} from "src/entities/project/participant-request";
import CircularProgress from "@mui/material/CircularProgress";
import ToggleButtonGroup, {
  toggleButtonGroupClasses,
} from "@mui/material/ToggleButtonGroup";
import {paths} from "src/pages/paths";
import {GifSplashScreen} from "src/components/gif-splash-screen";
import {Reward} from "src/pages/dashboard/super/participant-requests/participant-request-detail/reward";
import {ParticipantRequestMeta} from "src/pages/dashboard/super/participant-requests/participant-request-detail/participant-request-meta";
import {ProjectAssignmentBatchesTable} from "src/pages/dashboard/super/participant-requests/participant-request-detail/project-assignment-batches-table";
import {AddParticipantGroup} from "src/pages/dashboard/super/participant-requests/participant-request-detail/add-participant-group";
import {useAuth} from "src/hooks/use-auth";
import {ParticipantAssignmentFilter} from "src/pages/dashboard/super/participant-requests/participant-request-detail/participant-assignment-filter";
import {AddParticipant} from "src/pages/dashboard/super/participant-requests/participant-request-detail/add-participant";

const IconText = ({icon, label, value}) => (
  <Box sx={{display: "flex", alignItems: "center", mb: 1}}>
    <SvgIcon sx={{mr: 1}}>{icon}</SvgIcon>
    <Typography variant="body1">
      {label}: {value}
    </Typography>
  </Box>
);

const mockData = {
  completedTotal: 89,
  completedAssignedGroup: 27,
  assignedGroupCount: 127,
  assignedGroupMale: 60,
  assignedGroupFemale: 67,
  assignedGroupYoung: 30,
  assignedGroupOld: 40,
  requestCount: 300,
  requestMale: 150,
  requestFemale: 150,
  requestYoung: 100,
  requestOld: 200,
  surveyDescription: "X Company Customer Feedback Survey",
};

export const ParticipantRequestDetail = () => {
  const {t} = useTranslation();
  const {id} = useParams();
  const [participantRequestDetails, setParticipantRequestDetails] = useState(null);
  // Participant Settings
  const [newBatch, setNewBatch] = useState(null);
  const [loadingBatchTable, setLoadingBatchTable] = useState(false);

  useEffect(() => {
    superParticipantRequestService.getParticipantRequestDetails(id).then(response => {
      const details = new ParticipantRequestDetails(response);
      setParticipantRequestDetails(details);
    });
  }, []);

  if (participantRequestDetails === null) {
    return <GifSplashScreen />;
  }

  const handleNewAssignmentBatch = batch => {
    setNewBatch(batch);
  };

  const handleLoadingBatchTable = val => {
    setLoadingBatchTable(val);
  };

  return (
    <Container maxWidth="lg" sx={{padding: 2}}>
      <Typography variant="h4" gutterBottom>
        {t("findParticipantsRequest.findParticipantsRequest")}
      </Typography>

      <Typography>
        Project:{" "}
        {participantRequestDetails && (
          <Link
            href={paths.surveyBuilder.edit.replace(
              ":uuid",
              participantRequestDetails.projectId,
            )}
          >
            {participantRequestDetails.projectTitle}
          </Link>
        )}
      </Typography>

      <Typography>
        Request Description:{" "}
        {participantRequestDetails && participantRequestDetails.description}
      </Typography>

      <Typography variant="h6" gutterBottom sx={{mt: 2}}>
        {t("surveyStatus.title")}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{padding: 2, height: "100%"}}>
            <Typography variant="subtitle1" gutterBottom>
              {t("surveyStatus.completedTotal")}
            </Typography>
            <IconText
              icon={<PersonIcon />}
              label={t("surveyStatus.completedCount")}
              value={mockData.completedTotal}
            />
            <IconText
              icon={<GroupIcon />}
              label={t("surveyStatus.completedAssignedGroup")}
              value={mockData.completedAssignedGroup}
            />
            <Box sx={{display: "flex", justifyContent: "flex-end", mt: 2}}>
              {/*<Button*/}
              {/*  variant="outlined"*/}
              {/*  size="small"*/}
              {/*  onClick={handleAction("Create Group")}*/}
              {/*>*/}
              {/*  {t("surveyStatus.createGroup")}*/}
              {/*</Button>*/}
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper elevation={3} sx={{padding: 2, height: "100%"}}>
            <Typography variant="subtitle1" gutterBottom>
              {t("surveyStatus.assignedGroup")}
            </Typography>
            <IconText
              icon={<GroupIcon />}
              label={t("surveyStatus.assignedGroupCount")}
              value={mockData.assignedGroupCount}
            />
            <IconText
              icon={<PersonIcon />}
              label={t("surveyStatus.assignedGroupGender")}
              value={`${mockData.assignedGroupMale}, ${mockData.assignedGroupFemale}`}
            />
            <IconText
              icon={<EventIcon />}
              label={t("surveyStatus.assignedGroupAge")}
              value={`${mockData.assignedGroupYoung}, ${mockData.assignedGroupOld}`}
            />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={3} sx={{padding: 2}}>
            <Typography variant="subtitle1" gutterBottom>
              {t("surveyStatus.requestDetails")}
            </Typography>
            {participantRequestDetails === null ? (
              <CircularProgress size={24} color="primary" />
            ) : (
              <>
                <IconText
                  icon={<GroupIcon />}
                  label={t("surveyStatus.requestCount")}
                  value={participantRequestDetails.requestedCountParticipantCount}
                />
                <IconText
                  icon={<PersonIcon />}
                  label={t("surveyStatus.requestGender")}
                  value={participantRequestDetails.requestedGenders}
                />
                <IconText
                  icon={<EventIcon />}
                  label={t("surveyStatus.requestAge")}
                  value={participantRequestDetails.requestedAges}
                />
              </>
            )}
          </Paper>
        </Grid>
      </Grid>

      {/* ========= REWARD ========= */}
      <Reward projectQuestions={participantRequestDetails.projectQuestions} />
      <ParticipantRequestMeta />

      <AddParticipant
        handleNewAssignmentBatch={handleNewAssignmentBatch}
        handleLoadingBatchTable={handleLoadingBatchTable}
      />

      {loadingBatchTable ? (
        <CircularProgress size={24} color="inherit" />
      ) : (
        <ProjectAssignmentBatchesTable
          newBatch={newBatch}
          handleNewAssignmentBatch={handleNewAssignmentBatch}
        />
      )}
    </Container>
  );
};
