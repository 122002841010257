import React, {useState} from "react";
import {Button, Modal, Box, Typography, TextField, CircularProgress} from "@mui/material";
import {useFormik} from "formik";
import {useTranslation} from "react-i18next";
import {superRewardService} from "src/services/super/project/reward";
import {useAuth} from "src/hooks/use-auth";

const RejectRequestButtonAndModal = ({id, getRewardRequestDetails}) => {
  const {t} = useTranslation();
  const [open, setOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const auth = useAuth();

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    formik.resetForm();
  };

  const handleRejectPayment = () => {
    setIsSubmitting(true);
    const payload = {
      message: formik.values.rejectMessage,
    };

    superRewardService
      .rejectRewardRequest(id, payload)
      .then(response => {
        auth.showAlert("success", "Reward Request Rejected!");
        getRewardRequestDetails();
        setIsSubmitting(false);
        handleClose();
      })
      .catch(error => {
        const errorMessage =
          error.response?.data?.message ||
          "An error occurred while rejecting the reward request.";
        auth.showAlert("error", errorMessage);
        console.error("Error rejecting payment:", errorMessage);
        setIsSubmitting(false);
      });
  };

  const formik = useFormik({
    initialValues: {rejectMessage: ""},
    onSubmit: handleRejectPayment,
  });

  return (
    <>
      <Button
        disabled={isSubmitting}
        onClick={handleOpen}
        sx={{
          borderRadius: "20px",
          marginLeft: 1,
          marginRight: 1,
          paddingLeft: 2,
          paddingRight: 2,
          fontWeight: "bold",
        }}
        variant="outlined"
      >
        {isSubmitting ? (
          <CircularProgress size={24} color="inherit" />
        ) : (
          t("participantPaymentRequests.rejectPayment")
        )}
      </Button>

      <Modal open={open} onClose={handleClose} aria-labelledby="reject-modal">
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 3,
            borderRadius: 2,
          }}
        >
          <Typography id="reject-modal" variant="h6" gutterBottom>
            {t("participantPaymentRequests.confirmRejection")}
          </Typography>

          <form onSubmit={formik.handleSubmit}>
            <TextField
              fullWidth
              label={t("participantPaymentRequests.rejectionReason")}
              variant="outlined"
              multiline
              rows={3}
              name="rejectMessage"
              value={formik.values.rejectMessage}
              onChange={formik.handleChange}
              sx={{mb: 2}}
            />

            <Box sx={{display: "flex", justifyContent: "flex-end", gap: 2}}>
              <Button onClick={handleClose} color="primary">
                {t("participantPaymentRequests.cancel")}
              </Button>
              <Button
                variant="contained"
                color="error"
                type="submit"
                disabled={isSubmitting || !formik.values.rejectMessage.trim()}
              >
                {isSubmitting ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  t("participantPaymentRequests.rejectPayment")
                )}
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>
    </>
  );
};

export default RejectRequestButtonAndModal;
