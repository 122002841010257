import {
  Card,
  CardContent,
  Typography,
  Avatar,
  Box,
  Divider,
  Stack,
  CircularProgress,
} from "@mui/material";
import {useEffect, useState} from "react";
import {superTeamService} from "src/services/super/team";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";

export const MemberInformation = () => {
  const {teamMemberId} = useParams();
  const {t} = useTranslation();
  const [memberData, setMemberData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchMemberData = async () => {
    try {
      const response = await superTeamService.getTeamMemberDetails(teamMemberId);
      setMemberData(response);
    } catch (err) {
      setError("Üye bilgileri alınamadı.");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMemberData();
  }, []);

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }
  return (
    <Card sx={{p: 3, borderRadius: 3, boxShadow: 3, maxWidth: 400}}>
      <CardContent>
        <Box display="flex" alignItems="center" mb={2}>
          <Avatar sx={{width: 50, height: 50, mr: 2}} />
          <Typography variant="h7" fontWeight="bold">
            {memberData.email}
          </Typography>
        </Box>

        <Divider sx={{mb: 2}} />

        <Stack spacing={2}>
          <Box>
            <Typography variant="body2" color="textSecondary">
              {t("editInfo.mail")}
            </Typography>
            <Typography variant="body1">{memberData.email}</Typography>
          </Box>

          <Box>
            <Typography variant="body2" color="textSecondary">
              {t("editInfo.phone")}
            </Typography>
            <Typography variant="body1">{memberData.phone}</Typography>
          </Box>

          <Box>
            <Typography variant="body2" color="textSecondary">
              {t("editInfo.birthDate")}
            </Typography>
            <Typography variant="body1">{memberData.birth_date}</Typography>
          </Box>

          <Box>
            <Typography variant="body2" color="textSecondary">
              {t("editInfo.gender")}
            </Typography>
            <Typography variant="body1">{t(`gender.${memberData.gender}`)}</Typography>
          </Box>

          <Box>
            <Typography variant="body2" color="textSecondary">
              {t("editInfo.country")}
            </Typography>
            <Typography variant="body1">{memberData.country || "Bilgi Yok"}</Typography>
          </Box>

          <Box>
            <Typography variant="body2" color="textSecondary">
              {t("teamMemberRole.role")}
            </Typography>
            <Typography variant="body1">
              {t(`teamMemberRole.${memberData.role}`)}
            </Typography>
          </Box>

          <Box>
            <Typography variant="body2" color="textSecondary">
              {t("fullName")}
            </Typography>
            <Typography variant="body1">{memberData.bank_full_name}</Typography>
          </Box>

          <Box>
            <Typography variant="body2" color="textSecondary">
              {t("bankName")}
            </Typography>
            <Typography variant="body1">{memberData.bank_name}</Typography>
          </Box>

          <Box>
            <Typography variant="body2" color="textSecondary">
              {t("iban")}
            </Typography>
            <Typography variant="body1">{memberData.bank_iban}</Typography>
          </Box>

          <Box>
            <Typography variant="body2" color="textSecondary">
              {t("identityNumber")}
            </Typography>
            <Typography variant="body1">{memberData.bank_identity_number}</Typography>
          </Box>

          <Box>
            <Typography variant="body2" color="textSecondary">
              {t("address")}
            </Typography>
            <Typography variant="body1">
              {memberData.bank_province
                ? `${memberData.bank_province}, ${memberData.bank_neighborhood}, ${memberData.bank_street}, ${memberData.bank_building}, ${memberData.bank_postal_code}`
                : memberData.bank_address}
            </Typography>
          </Box>
        </Stack>
      </CardContent>
    </Card>
  );
};
