import {
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import {useEffect, useState} from "react";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import {LoadingButton} from "@mui/lab";
import {superProjectService} from "src/services/super/project/project";
import MenuItem from "@mui/material/MenuItem";
import GenericTablePagination from "src/components/generic/GenericTablePagination";

export const CreateRewardProject = () => {
  const [page, setPage] = useState(0);
  const [amount, setAmount] = useState(null);
  const [loadingTable, setLoadingTable] = useState(false);
  const [projects, setProjects] = useState([]);
  const [projectCount, setProjectCount] = useState(0);
  const [title, setTitle] = useState(null);
  const [description, setDescription] = useState(null);
  const [source, setSource] = useState(null);
  const [isCreateRewardLoading, setIsCreateRewardLoading] = useState(false);

  const fetchProjects = _page => {
    setLoadingTable(true);
    superProjectService.listRewardProject(_page + 1).then(response => {
      setProjects(response.results);
      setProjectCount(response.count);
      setPage(_page);
      setLoadingTable(false);
    });
  };

  useEffect(() => {
    fetchProjects(page);
  }, []);

  const handleCreateRewardProject = () => {
    setIsCreateRewardLoading(true);
    const payload = {
      title: title,
      description: description,
      amount: amount,
      source: source,
    };
    superProjectService.createRewardProject(payload).then(newProject => {
      if (projects.length === 10) {
        setProjects([newProject, ...projects.slice(0, -1)]);
      } else {
        setProjects([newProject, ...projects]);
      }
      setIsCreateRewardLoading(false);
    });
  };

  const renderTableBody = () => {
    if (loadingTable) {
      return Array.from(new Array(5)).map((_, index) => (
        <TableRow key={index}>
          <TableCell>
            <Skeleton variant="text" width={200} />
          </TableCell>
          <TableCell>
            <Skeleton variant="text" width={200} />
          </TableCell>
          <TableCell>
            <Skeleton variant="text" width={200} />
          </TableCell>
          <TableCell>
            <Skeleton variant="text" width={100} />
          </TableCell>
          <TableCell>
            <Skeleton variant="text" width={200} />
          </TableCell>
        </TableRow>
      ));
    }

    return projects.map(project => (
      <TableRow key={project.id} hover style={{cursor: "pointer"}}>
        <TableCell>{project.id}</TableCell>
        <TableCell>{project.title}</TableCell>
        <TableCell>{project.description}</TableCell>
        <TableCell>{project.amount}</TableCell>
        <TableCell>{project.source}</TableCell>
      </TableRow>
    ));
  };

  return (
    <>
      <Stack sx={{mt: 2}} spacing={12} direction={"row"}>
        <TextField
          fullWidth
          label="Topic"
          value={title}
          onChange={e => setTitle(e.target.value)}
        />
      </Stack>
      <Stack sx={{mt: 2}} spacing={12} direction={"row"}>
        <TextField
          fullWidth
          label="Description"
          value={description}
          rows={4}
          multiline
          onChange={e => setDescription(e.target.value)}
        />
      </Stack>
      <Stack sx={{mt: 2}} spacing={2} direction={"row"}>
        <TextField
          fullWidth
          label="Amount"
          value={amount}
          onChange={e => setAmount(e.target.value)}
          type={"number"}
        />
      </Stack>
      <Stack sx={{mt: 2}} spacing={2} direction={"row"}>
        <TextField
          fullWidth
          label="Source"
          name="source"
          select
          value={source}
          onChange={e => setSource(e.target.value)}
          sx={{px: 0}}
        >
          <MenuItem value="qualtrics">Qualtrics</MenuItem>
        </TextField>
      </Stack>
      <Stack sx={{my: 2}} spacing={12} direction={"row"}>
        <LoadingButton
          fullWidth
          loading={isCreateRewardLoading}
          disabled={!(amount && title)}
          onClick={handleCreateRewardProject}
          sx={{mt: 3}}
          size="large"
          type="submit"
          variant="contained"
        >
          Create
        </LoadingButton>
      </Stack>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Topic</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell>Source</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{renderTableBody()}</TableBody>
        </Table>
      </TableContainer>
      <GenericTablePagination
        count={projectCount}
        onPageChange={newPage => fetchProjects(newPage)}
        page={page}
        rowsPerPage={10}
      />
    </>
  );
};
